import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CarRentalCardComponent.scss";
import { Link } from "react-router-dom";

function CarRentalCardComponent({ vehicleDetails, index }) {
  return (
    <article className="flex flex-col px-0.5 tracking-tighter max-w-[250px] my-5">
      <div className="flex flex-col justify-center">
        <div className="flex overflow-hidden flex-col self-center w-full text-xl leading-tight text-center text-orange-600 bg-white rounded-xl font-[450] sh adow-[4px_4px_23px_rgba(244,103,2,0.19)]">
          <div className="flex relative flex-col w-full aspect-[1.206]">
            <CarRentalCardSlideComponent
              images={vehicleDetails?.vehicle_images}
            />
          </div>
        </div>
        <Link to={`./${index}`}>
          {" "}
          <div className="flex gap-10 justify-center items-center w-full text-2xl font-bold leading-7 mt-3.5 text-neutral-900">
            <h2 className="my-auto">
              {vehicleDetails?.vehicle_company} {vehicleDetails?.model}
            </h2>
            <img
              loading="lazy"
              src={
                "https://cdn.builder.io/api/v1/image/assets/TEMP/cd364c26a94082727869cafc6aba537841ba015b166d738b70cc2267c548661d?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
              }
              alt={"alt"}
              className={
                "object-contain shrink-0 self-stretch my-auto aspect-[0.97] w-[35px]"
              }
            />
          </div>
          <div className="flex justify-between  w-full items-center self-start mt-3.5 text-xl leading-none text-neutral-900">
            <div className="flex flex-col justify-center self-stretch my-auto font-[450]">
              <p>{vehicleDetails?.timeline_rent}</p>
              <p className="mt-2">{vehicleDetails?.available_date}</p>
            </div>
            <div className="flex flex-col justify-center items-end self-stretch my-auto text-right">
              <p className="font-light">{vehicleDetails?.distance_allowed}</p>
              <p className="mt-2 font-bold">₦35,000/day</p>
            </div>
          </div>{" "}
        </Link>
      </div>
    </article>
  );
}

export default CarRentalCardComponent;

function CarRentalCardSlideComponent({ images }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      infinite={true}
      // autoPlay={this.props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      // customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      // deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {images?.length ? (
        images.map((image, index) => (
          <div
            className="size-full cursor-pointer overflow-hidden rounded"
            key={index}
          >
            <img
              src={image}
              alt={`Car exterior ${index + 1}`}
              className="object-cover object-center size-full"
            />
          </div>
        ))
      ) : (
        // Render a placeholder if images is empty
        <div className="size-full cursor-pointer overflow-hidden rounded">
          <img
            src="https://archive.org/download/placeholder-image/placeholder-image.jpg"
            alt="Placeholder"
            className="object-cover object-center size-full"
          />
        </div>
      )}
    </Carousel>
  );
}
