import React from "react";
import { Link } from "react-router-dom";

function SearchFormComponent({ filterToggle }) {
  return (
    <form
      className={`flex flex-col ${
        filterToggle ? "w-[31%]" : "w-0 overflow-hidden"
      }  max-md:ml-0 max-md:w-full`}
    >
      <div className="flex flex-col self-stretch mt-[10px] text-xl tracking-tight leading-6 font-[450] text-neutral-900 text-opacity-70 max-md:mt-10 max-md:max-w-full">
        <SearchField
          label="Vehicle Type"
          iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/49c7730df689bf3bb4b941ef7ebdbbcec031e2f138b7724c1687b483d5e70389?apiKey=e85d4038ac5a41748b51322169f61f43&"
        />
        <SearchField
          label="Vehicle Company"
          iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/bf44257612f0dcc498271afb20bc331cd8fa44ed8c7b2e29bc8c069c5de2f7a3?apiKey=e85d4038ac5a41748b51322169f61f43&"
          className="mt-16 max-md:mt-10"
        />
        <div className="flex gap-5 mt-10">
          <SearchField
            label="Name of vehicle"
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/03390a6e60798a1e3cbd083ef7f7e246ba7c69f416ef57ae25c39675d400c68f?apiKey=e85d4038ac5a41748b51322169f61f43&"
            className="flex-1"
          />
          <SearchField
            label="Model"
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/017b89ec4a7c7ef16bfab0995cfd2db305e678336ed388f0b00dd51bbfbe5472?apiKey=e85d4038ac5a41748b51322169f61f43&"
            className="flex-1"
          />
        </div>
        <SearchField
          label="Timeline available for rent"
          iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/b73ceeb1ffbd6ff80b2319d6d7bf5525fc00d873797cd207d461a4e942ea609e?apiKey=e85d4038ac5a41748b51322169f61f43&"
          className="mt-16 max-md:mt-10"
        />
        <div className="flex gap-5 mt-10 whitespace-nowrap">
          <div className="justify-center items-start px-7 py-3.5 rounded border border-solid border-neutral-900 max-md:px-5">
            Date
          </div>
          <SearchField
            label="Distance"
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/692ac143ea3d357130bb540796162c6dfd093def036868ba6af19a491ef2ee7d?apiKey=e85d4038ac5a41748b51322169f61f43&"
            className="flex-1"
          />
        </div>
        <Link
          // type="submit"
          to={"/search/result"}
          type="button"
          className="justify-center items-center p-3 mt-20 font-medium tracking-tight text-center text-white bg-orange-600 rounded-xl border border-solid shadow-sm border-black border-opacity-60 leading-[135%] max-md:px-5 max-md:mt-10 max-md:max-w-full"
        >
          Search for vehicle
        </Link>
      </div>
    </form>
  );
}

export default SearchFormComponent;

const SearchField = ({ label, iconSrc, className = "" }) => {
  return (
    <div
      className={`flex gap-5 justify-between px-7 py-3.5 rounded-md border border-solid border-neutral-900 max-md:flex-wrap max-md:px-5 max-md:max-w-full ${className}`}
    >
      <div className="my-auto">{label}</div>
      <img
        loading="lazy"
        src={iconSrc}
        alt=""
        className="shrink-0 aspect-square w-[29px]"
      />
    </div>
  );
};

export const SearchBarComponent = ({ handelInputChanges }) => {
  return (
    <form className="SearchBarComponent w-full">
      <label
        htmlFor="locationSearch"
        className="flex gap-3 px-5 h-[48px] md:h-[80px] items-center md:text-xl tracking-tighter leading-6 text-center rounded-[8px] md:rounded-2xl border-2 border-solid shadow-sm border-neutral-800 font-[450] text-neutral-900 text-opacity-80 w-full"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/475f2d6f5083d0752debe20bcc50dfcdbdc6cbed15e1bc97d871ce4cc0816581?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt=""
          className="w-[20px] h-[20px] shrink-0 md:w-[40px] md:h-[40px] aspect-square my-auto"
        />

        <input
          type="text"
          id="locationSearch"
          placeholder="Search for location you want a vehicle..."
          className=" my -auto bg-transparent border-none outline-none h-full w-full"
          name="key"
          onChange={handelInputChanges}
        />
      </label>
    </form>
  );
};
