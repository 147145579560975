import React, { useContext, useEffect, useState } from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";
import {
  BackArrowIconSvg,
  HostedVehiclesIconSvg,
  InspectionIconSvg,
  RevenueIconSvg,
} from "../../icons";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
} from "@chakra-ui/react";
import { useDataContext } from "../../Context/dataContext";
import { useAuthContext } from "../../Context/authContext";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { toast } from "react-toastify";
import ModelPopupComponent from "../../components/ModelPopupComponent/ModelPopupComponent";
import { ModalContext } from "../../Context/modalContext";
import {
  InputField,
  SelectDropDownField,
} from "../HostVehiclePage/HostVehiclePage";
function DashboardPage() {
  const { profile, accountType } = useAuthContext();
  const { payments, ownerListedVehicle } = useDataContext();
  const [activeTabNavigation, setActiveTabNavigation] = useState(0);
  const [tabNavigation, setTabNavigation] = useState([]);

  useEffect(() => {
    if (accountType === "owner") {
      setTabNavigation([
        {
          icon: <HostedVehiclesIconSvg />,
          name: "   Hosted vehicles",
          content: <OwnerVehicleListComponent />,
        },
        {
          icon: <RevenueIconSvg />,
          name: " Revenue",
          content: <RevenueTableComponent />,
        },
        /* Commented out cause not sure owner requires inspection data */
        /*{
          icon: <InspectionIconSvg />,
          name: " Inspection",
          content: <InspectionTableComponent />,
        },*/
      ]);
    }
    if (accountType === "rider") {
      setTabNavigation([
        {
          icon: <HostedVehiclesIconSvg />,
          name: "Assigned vehicles",
          content: <AssignedVehicleComponent />,
        },
        {
          icon: <RevenueIconSvg />,
          name: "Payment",
          content: <RiderPaymentTableComponent />,
        },
      ]);
    }
    // console.log({ profile });
  }, [accountType]);

  return (
    <main className="flex overflow-hidden flex-col pb-56 bg-white max-md:pb-24 ">
      <div className="fixed t-0 w-full bg-white">
        <NavigationComponent />
      </div>
      <section className="mt-24 w-full  flex">
        <div className="w-[1128px] h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
          <div className="text-[#121212] text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Dashboard
          </div>
          {/* <div className="justify-center items-center gap-3 flex">
            <img
              className="w-[60px] h-[60px]  rounded-[100px]"
              src="https://via.placeholder.com/60x60"
            />
            <div className="flex-col justify-center items-start gap-3 inline-flex">
              <div className="text-[#121212]/70 text-base font-['Circular Std'] leading-[13px]">
                Rider
              </div>
              <div className="text-[#121212] text-xl font-bold font-['Circular Std'] leading-[13px]">
                Switch{" "}
              </div>
            </div>
          </div> */}
          <Link
            to={"./profile"}
            className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white"
          >
            Profile
          </Link>
        </div>
      </section>

      <section className="prompt_notification flex flex-col gap-2 items-center">
        {/* <PromptNotificationCardComponent
          // type="info"
          type="error"
          message={"Account was suspended by admin"}
          actionUrl={"/"}
          isAction={false}
        />
        <PromptNotificationCardComponent
          type="info"
          message={"Complete Account Setup"}
          actionUrl={`./setup-account?action=${accountType}`}
          isAction={true}
        /> */}
        {payments == null ? (
          <div className="flex justify-center mt-10">
            <Spinner className="mx-auto" />
          </div>
        ) : (
          <>
            {profile?.guarantor_documents?.length < 2 && (
              <PromptNotificationCardComponent
                type="info"
                message={"Complete your guarantor form"}
                actionUrl={`./profile`}
                isAction={true}
              />
            )}
            {profile?.verification_documents?.length < 3 && (
              <PromptNotificationCardComponent
                type="info"
                message={"Complete your verification documents"}
                actionUrl={`./profile`}
                isAction={true}
              />
            )}
            {profile?.verification_documents?.some(
              (document) => !document?.verified
            ) && (
              <PromptNotificationCardComponent
                type="info"
                message={"Verification documents not verified"}
                actionUrl={`./profile`}
                isAction={false}
              />
            )}
            {profile?.guarantor_documents?.some(
              (document) => !document?.verified
            ) && (
              <PromptNotificationCardComponent
                type="info"
                message={"Guarantor not verified"}
                actionUrl={`./profile`}
                isAction={false}
              />
            )}
            {!profile?.account_verified && (
              <PromptNotificationCardComponent
                type="info"
                message={"Account is not yet verified"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
            {!profile?.account_active && (
              <PromptNotificationCardComponent
                type="danger"
                message={"Account has been deactivated. contact admin"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
            {!profile?.account_active && (
              <PromptNotificationCardComponent
                type="danger"
                message={"Account has been deactivated. contact admin"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
          </>
        )}
      </section>

      <section className="flex  w-[1128px] mx-auto">
        <div className="w-full md:w-[70%] ">
          {accountType === "rider" && (
            <>
              {" "}
              {payments.loading ? (
                <div className="flex justify-center mt-10">
                  <Spinner className="mx-auto" />
                </div>
              ) : (
                <>
                  {payments.data.map((payment, index) => {
                    if (payment.payment_status === "paid") return;
                    return (
                      <PaymentPromptNotificationComponent
                        payment={payment}
                        key={index}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
          {/* NOTE: this is the vehicle owners wallet */}
          {accountType === "owner" && (
            <>
              {" "}
              {!profile.wallet ? (
                <div className="flex justify-center mt-10">
                  <Spinner className="mx-auto" />
                </div>
              ) : (
                <div className="my-5 flex gap-5">
                  <div className="border border-[#909090] rounded-md p-5 w-[250px]">
                    <h3 className="text-[20px] font-bold flex gap-.5 items-center mb-3">
                      {" "}
                      <RevenueIconSvg />
                      Wallet:
                    </h3>
                    {profile.wallet ? (
                      <span className="text-[25px] ms-5">
                        ₦{profile?.wallet?.balance}
                      </span>
                    ) : (
                      <ModelPopupComponent
                        trigger={
                          <button className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white">
                            Create Wallet
                          </button>
                        }
                      >
                        <CreateWalletModelContentComponent />
                      </ModelPopupComponent>
                    )}
                  </div>
                  <div className="border border-[#909090] rounded-md p-5 w-[250px]">
                    <h3 className="text-[20px] font-bold flex gap-.5 items-center mb-3">
                      {" "}
                      <HostedVehiclesIconSvg />
                      Vehicles:
                    </h3>
                    <span className="text-[25px] ms-5">
                      {ownerListedVehicle.data.length}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="  mx-auto mt-3 mb-5">
            <div className="h-16 justify-start items-start gap-5 inline-flex">
              {tabNavigation.map((tab, index) => (
                <div
                  className={`px-2 py-3 ${
                    index === activeTabNavigation &&
                    " bg-neutral-100 rounded-md"
                  } justify-center items-center gap-2.5 flex`}
                  onClick={() => setActiveTabNavigation(index)}
                  key={index}
                >
                  <div className="w-10 h-10">{tab.icon}</div>
                  <div
                    className={`${
                      index === activeTabNavigation
                        ? "text-[#121212] font-medium"
                        : "text-[#121212]/70"
                    } text-xl  font-['Circular Std'] leading-[14px] cursor-pointer`}
                  >
                    {tab.name}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="  mx-auto flex flex-col flex-wrap justify-evenly">
            {tabNavigation[activeTabNavigation]?.content}
          </div>
        </div>
        <div className=" w-full md:w-[30%]">
          <NotificationComponent />
        </div>
      </section>
    </main>
  );
}

export default DashboardPage;

function HostedVehiclesCardComponent({ vehicle }) {
  return (
    <>
      <Link
        to={`./vehicle/${vehicle?._id}`}
        className="w-full p-[10px] flex  justify-between bg-white rounded-lg border border-[#909090] cursor-pointer mb-3"
      >
        <div className="flex gap-5  w-full  pe-3">
          <div className="w-[115px] h-[100px] gap-2 rounded-md border border-[#909090]">
            <img
              className="w-full h-full object-center object-cover"
              src={
                vehicle?.vehicle_images[0] ||
                "https://via.placeholder.com/145x97"
              }
            />
          </div>
          <div className="my-auto">
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              {vehicle?.vehicle_company} {vehicle?.model}
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.vehicle_type}
            </span>
            <div className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px] flex gap-1">
              {vehicle?.verified_vehicle ? (
                <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                  Verified
                </div>
              ) : (
                <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                  Not Verified
                </div>
              )}
              {vehicle?.active_vehicle ? (
                <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                  Active
                </div>
              ) : (
                <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                  Not Active
                </div>
              )}
            </div>
          </div>
          <div className="my-auto">
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              Engine:
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.health_status?.engine}
            </span>
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              Body exterior:
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.health_status?.body_exterior}
            </span>
          </div>
          <div className="ms-auto my-auto">
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {moment(vehicle?.createdAt).format("LL")}
            </span>
          </div>
        </div>
      </Link>
      {/* )} */}
    </>
  );
}

function RevenueTableComponent() {
  const [startPage, setStartPage] = useState(0);
  const [revenueData, setRevenueData] = useState([
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      amount: 200,
      paid: 200,
      completed: "yes",
      date: "25th Jul 2024",
    },
  ]);

  function nextPageFunction() {}
  function prevPageFunction() {}
  return (
    <TableContainer maxWidth="100%">
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th className="w-52">Number</Th>
            <Th className="w-52">Name</Th>
            <Th className="w-52">Amount</Th>
            <Th className="w-52">Paid</Th>
            <Th className="w-52">Completed </Th>
            <Th className="w-52">Date </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* TODO: this is the owner teable */}
          {revenueData?.map((data, index) => (
            // {revenueData?.slice(startPage, -10)?.map((data, index) => (
            <Tr className="" key={index}>
              <Td>{data.number}</Td>
              <Td>{data.name}</Td>
              <Td>₦{data.amount}</Td>
              <Td>₦{data.paid}</Td>
              <Td>{data.completed}</Td>
              <Td>{data.date}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function RiderPaymentTableComponent() {
  const { payments } = useDataContext();

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = payments.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, payments]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <div className="mt-3 flex justify-between me-7 mb-5">
        <div className=" border flex gap-5">
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Overdue{" "}
            <div className="bg-red-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Pending{" "}
            <div className="bg-yellow-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Paid{" "}
            <div className="bg-green-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
        </div>
        <div className="">
          <select
            name="count"
            id="count"
            className="mx-3 me-5 my-auto border rounded p-0.5"
            onChange={(e) => setCountPerPage(() => e.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <button onClick={prevPageFunction} className="btn btn-sm btn-primary">
            Prev
          </button>{" "}
          <span className="mx-3 ">
            {currentPage + 1} of {totalPage}
          </span>
          <button onClick={nextPageFunction} className="btn btn-sm btn-primary">
            Next
          </button>
        </div>
      </div>
      {payments.loading ? (
        <div className="w-full flex justify-center ">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      ) : (
        <TableContainer maxWidth="100%">
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th className="w-52">Rider</Th>
                <Th className="w-52">Vehicle</Th>
                <Th className="w-52">Payment</Th>
                <Th className="w-52">Status</Th>
                <Th className="w-52">Amount</Th>
                {/* <Th className="w-52">Overdue</Th> */}
                <Th className="w-52">Date </Th>
              </Tr>
            </Thead>

            <Tbody>
              {payments.data
                ?.slice(
                  currentPage * countPerPage,
                  (currentPage + 1) * countPerPage
                )
                .map((data, index) => (
                  // {revenueData?.slice(startPage, -10)?.map((data, index) => (
                  <Tr className="" key={index}>
                    <Td>{data.rider.full_name}</Td>
                    <Td>{data.vehicle.model}</Td>
                    <Td>{data.description}</Td>
                    <Td>
                      <div
                        className={`
                        ${data.payment_status === "paid" && "bg-green-500"}
                        ${data.payment_status === "pending" && "bg-yellow-500"}
                        ${data.payment_status === "overdue" && "bg-red-500"}
                        p-0.5 text-center rounded text-white`}
                      >
                        {data.payment_status}
                      </div>
                    </Td>
                    <Td>₦{data.payment_amount + data.overdue_charges}</Td>
                    <RiderPaymentButtonComponent payment={data} />
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function RiderPaymentButtonComponent({ payment }) {
  const { endpoint, token, profile } = useAuthContext();
  const { paystackPublicKey, getPaymentsFunction, getNotificationFunction } =
    useDataContext();

  // console.log({ payment });

  const componentProps = {
    email: profile.email,
    amount: (payment.payment_amount + payment.overdue_charges) * 100, // Convert to kobo
    metadata: {
      riderId: payment.rider._id,
      vehicleId: payment.vehicle._id,
    },
    publicKey: paystackPublicKey,
    text: `Pay ₦${(
      payment.payment_amount + payment.overdue_charges
    ).toLocaleString()} `,
    onSuccess: async (response) => {
      // Payment successful, update database
      const requestResponce = await handelPaymentFunction(response);
      if (requestResponce.statusText === "OK") {
        getNotificationFunction();
        getPaymentsFunction();
        toast.success("Payment successful");
      } else {
        console.log({ requestResponce });
      }
    },
    onClose: () => {
      // Payment modal closed
      console.log("Payment modal closed");
    },
  };
  async function handelPaymentFunction(paymentResponce) {
    // console.log({ paymentResponce });
    // return console.log("handelPaymentFunction", { vID: payment.vehicle });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/payment?payment_id=${payment._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: { payment: paymentResponce, vehicle_id: payment.vehicle._id },
    };

    try {
      let response = await axios.request(config);
      console.log({ response });
      return response;
    } catch (error) {
      return error.response;
    }
  }
  return (
    <>
      {payment.payment ? (
        <Td>{moment(payment.payment_date).format("MMM Do YYYY")}</Td>
      ) : (
        <Td>
          {" "}
          <PaystackButton
            className="bg-[#F46702] flex items-center rounded-md my-auto h-[35px] px-2 text-white"
            {...componentProps}
          />
        </Td>
      )}
    </>
  );
}

function InspectionTableComponent() {
  const [inspectionData, setInspectionData] = useState([
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
  ]);
  return (
    <TableContainer>
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th className="w-52">Number</Th>
            <Th className="w-52">Name</Th>
            <Th className="w-52">Scheduled date</Th>
            <Th className="w-52">Status</Th>
            <Th className="w-52">Next </Th>
          </Tr>
        </Thead>
        <Tbody>
          {inspectionData.map((data, index) => (
            <Tr key={index}>
              <Td>{data.number}</Td>
              <Td>{data.name}</Td>
              <Td>{data.scheduledDate}</Td>
              <Td>{data.status}</Td>
              <Td>{data.nextDate}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function OwnerVehicleListComponent() {
  const { ownerListedVehicle } = useDataContext();

  return (
    <>
      {ownerListedVehicle.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <>
          {ownerListedVehicle.data.length > 0 ? (
            ownerListedVehicle.data.map((vehicle, index) => (
              <HostedVehiclesCardComponent vehicle={vehicle} key={index} />
            ))
          ) : (
            <div className="text-center">
              <p className="text-lg font-semibold">No Vehicles Hosted</p>
              <Link
                to={"host-vehicle"}
                className="my-auto text-base font-bold tracking-tight leading-3 text-neutral-900"
              >
                <button className="btn bg-orange-400 px-3 py-5 rounded-lg cursor-pointer">
                  Host a Vehicle
                </button>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
}

function BookedVehicleListComponent() {
  const { bookedListedVehicle } = useDataContext();
  // useEffect(() => {
  //   console.log({ bookedListedVehicle });
  // }, [bookedListedVehicle]);
  return (
    <>
      {" "}
      {bookedListedVehicle.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <>
          {bookedListedVehicle.data.length > 0 && (
            <span className="border-y-2 block py-4 my-4 text-[#121212] font-medium text-xl  font-['Circular Std'] leading-[14px]">
              Booked Vehicle{" "}
            </span>
          )}
          {bookedListedVehicle.data.map((vehicle, index) => {
            return (
              <HostedVehiclesCardComponent vehicle={vehicle} key={index} />
            );
          })}
        </>
      )}
    </>
  );
}
function AssignedVehicleComponent() {
  const { assignedVehicle } = useDataContext();

  return (
    <>
      {" "}
      {assignedVehicle.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <div className="">
          {/* {ownerListedVehicle.data.map((vehicle, index) => {
            return (
              <HostedVehiclesCardComponent vehicle={vehicle} key={index} />
              );
              })} */}
          {assignedVehicle.data ? (
            <HostedVehiclesCardComponent vehicle={assignedVehicle.data} />
          ) : (
            <div className="flex flex-col  items-center">
              No Active Vehicle
              <div className="mt-5">
                <Link
                  to={"/search"}
                  className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white w-40 justify-center"
                >
                  Rent A Vehicle
                </Link>
              </div>
            </div>
          )}

          <BookedVehicleListComponent />
        </div>
      )}
    </>
  );
}

function PromptNotificationCardComponent({
  type,
  message,
  actionUrl,
  isAction,
}) {
  let color = type === "danger" ? "bg-red-500/50 text-black" : "bg-[#6cd7f8a2]";
  return (
    <div
      className={`flex items-center justify-between px-5 h-[60px] w-[1128px] border-[2px] border-[#121212] rounded-md ${color}`}
    >
      <b>{message}</b>

      {isAction && (
        <Link
          to={actionUrl}
          className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white"
        >
          Take Action
        </Link>
      )}
    </div>
  );
}

function NotificationComponent() {
  const { notificationList } = useDataContext();

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = notificationList.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, notificationList]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex flex-col px-3 w-full  ">
      <b className="block mt-5">Notification</b>

      {notificationList.loading ? (
        <Spinner className="mx-auto mt-5" />
      ) : (
        <>
          {notificationList.data
            ?.slice(
              currentPage * countPerPage,
              (currentPage + 1) * countPerPage
            )
            .map((notification, index) => {
              return (
                <div
                  key={index}
                  className="bg-[#dedede] p-2 my-2 flex flex-col"
                >
                  {notification.message}
                  <div className="text-xs mt-0.5">
                    {" "}
                    {moment(notification.createdAt).format("llll")}
                  </div>
                </div>
              );
            })}
          <div className="mt-3">
            <button
              onClick={prevPageFunction}
              className="btn btn-sm btn-primary"
            >
              Prev
            </button>{" "}
            <span className="mx-3 ">
              {currentPage + 1} of {totalPage}
            </span>
            <button
              onClick={nextPageFunction}
              className="btn btn-sm btn-primary"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function PaymentPromptNotificationComponent({ payment }) {
  const { endpoint, token, profile } = useAuthContext();
  const { paystackPublicKey, getPaymentsFunction, getNotificationFunction } =
    useDataContext();

  // useEffect(() => {
  //   console.log({ payment });
  // }, [payment]);

  const componentProps = {
    email: profile.email,
    amount: (payment.payment_amount + payment.overdue_charges) * 100, // Convert to kobo
    metadata: {
      riderId: payment.rider._id,
      vehicleId: payment.vehicle._id,
    },
    publicKey: paystackPublicKey,
    text: `Make ₦${(
      payment.payment_amount + payment.overdue_charges
    ).toLocaleString()} Payment`,
    onSuccess: async (response) => {
      // Payment successful, update database
      const requestResponce = await handelPaymentFunction(response);

      if (requestResponce.statusText === "OK") {
        getNotificationFunction();
        getPaymentsFunction();
        toast.success("Payment successful");
      } else {
        console.log({ requestResponce });
      }
    },
    onClose: () => {
      toast.info("Payment modal closed");
      // Payment modal closed
      console.log("Payment modal closed");
    },
  };

  async function handelPaymentFunction(paymentResponce) {
    // console.log({ paymentResponce });
    // console.log({ payment });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/payment?payment_id=${payment._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: { payment: paymentResponce, vehicle_id: payment.vehicle._id },
    };

    try {
      let response = await axios.request(config);
      // console.log({ response });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  return (
    <div
      className={`"payment-container border-[2px] border-[#121212] rounded-md px-3 h-14 mt-3 flex justify-between items-center ${
        payment.payment_status === "overdue" && "bg-red-500/50 "
      }`}
    >
      {/* Weekly Remittance Payment */}
      <div className="flex gap-2 items-center">
        {payment.description}{" "}
        {payment.overdue_charges ? (
          <div className="bg-red-900 text-white rounded py-0.5 p-2 ">
            +{payment.overdue_charges}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>{moment(payment.payment_due_date, "YYYYMMDD").fromNow()}</div>
      <PaystackButton
        className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white"
        {...componentProps}
      />
    </div>
  );
}

function CreateWalletModelContentComponent() {
  const { closeModelFunction } = useContext(ModalContext);
  const { endpoint, token, getProfileUpdateFunction } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});
  useEffect(() => {
    getBanksCodes();
  }, []);
  // useEffect(() => {
  //   // Check if account number is valid and selectedBank exists
  //   if (
  //     !accountInfo?.account_number ||
  //     accountInfo?.account_number?.length < 10
  //   )
  //     return;
  //   if (!accountInfo.selectedBank) return;

  //   // Call VerifyAccountNumber and handle the promise
  //   VerifyAccountNumber(
  //     accountInfo.account_number,
  //     accountInfo.selectedBank.code
  //   )
  //     .then((accountDetail) => {
  //       console.log(accountDetail);

  //       // Update accountInfo with the returned account name
  //       setAccountInfo((prev) => ({
  //         ...prev,
  //         account_name: accountDetail?.account_name,
  //       }));
  //     })
  //     .catch((error) => {
  //       console.error("Account verification failed:", error);
  //     });
  // }, [accountInfo.account_number, accountInfo.selectedBank]);

  useEffect(() => {
    if (
      !accountInfo?.account_number ||
      accountInfo?.account_number?.length < 10
    ) {
      // Update state with the account name on account number change
      setAccountInfo((prev) => ({
        ...prev,
        account_name: null,
      }));
      return;
    }
    if (!accountInfo.selectedBank) {
      return;
    }
    setLoading(true);
    // Define an async function within the useEffect
    const fetchAccountDetails = async () => {
      try {
        const accountDetail = await VerifyAccountNumber(
          accountInfo.account_number,
          accountInfo.selectedBank.code
        );
        // console.log("Account Detail:", accountDetail);

        // Update state with the account name from the API
        setAccountInfo((prev) => ({
          ...prev,
          account_name: accountDetail.account_name,
        }));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
        console.error("Account verification failed:", error);
      }
    };

    // Call the async function
    fetchAccountDetails();

    // Optional cleanup if needed
  }, [accountInfo.account_number, accountInfo.selectedBank]);

  function getBanksCodes() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/withdrawal/banks`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.okay) {
          // console.log(response.data.banks);
          setBanks(response.data.banks);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // function VerifyAccountNumber(account_number, bank_code) {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${endpoint}/withdrawal/resolve-account?account_number=${account_number}&bank_code=${bank_code}`,
  //     headers: {
  //       Authorization: token,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       if (response.data.okay) {
  //         // resolve(response.data.accountDetail); // Resolve with the account detail data
  //         return response.data.accountDetail; // Return account detail on success
  //       } else {
  //         // throw new Error("Account verification failed"); // Throw error if not successful
  //         console.log("Account verification failed"); // Throw error if not successful
  //       }
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       // throw error; // Re-throw the error so the caller can handle it
  //     });
  // }

  async function VerifyAccountNumber(account_number, bank_code) {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/withdrawal/resolve-account?account_number=${account_number}&bank_code=${bank_code}`,
      headers: {
        Authorization: token,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data.okay) {
        return response.data.accountDetail; // Return account detail if the request was successful
      } else {
        throw new Error("Account verification failed");
      }
    } catch (error) {
      console.error("Error in VerifyAccountNumber:", error);
      throw error; // Rethrow the error so the caller can handle it
    }
  }

  async function createAccountFunction(account_number, bank_code) {
    const data = {
      pin: accountInfo.pin,
      bank: { ...accountInfo },
    };

    // return console.log({ data });
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/wallet/create`,
      headers: {
        Authorization: token,
      },
      data,
    };

    try {
      const response = await axios.request(config);
      console.log({ response });
      if (response.data.okay) {
        // return response.data.accountDetail; // Return account detail if the request was successful
        getProfileUpdateFunction();
        closeModelFunction();
      } else {
        toast.error(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error("Error in creating wallet");
      console.error("Error in creating wallet:", error);
      // throw error; // Rethrow the error so the caller can handle it
    }
  }

  function handelInputChanges(e) {
    // console.log(e.target.name, e.target.value);
    if (e.target.name === "account_number" && e.target.value.length > 10)
      return;
    setAccountInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handelSelectChanges(name, value) {
    // Update state with the account name on bank change
    setAccountInfo((prev) => ({
      ...prev,
      account_name: null,
    }));
    const selectedBank = banks.find((bank) => bank.name === value); // Find the bank object by name
    setAccountInfo((prev) => {
      return {
        ...prev,
        [name]: value,
        selectedBank: selectedBank,
      };
    });
  }

  const vehicleInfoFields = [
    {
      label: "Location",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ff5ee4ea1f046d7091e8aa945676da93bc80fc09026d068c5aee051f11d42bb?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      // optionData: ["Car", "Truck", "Motorcycle", "SUV"],
      name: "pick_up_location",
      optionData: ["FCT Abuja", "Lagos"],
    },
  ];

  return (
    <div className="min-h-[400px] p-[20px] h-[500px] w-[50%] bg-white rounded-xl shadow border border-[#666666]/80 flex-col inline-flex">
      <div className=" gap-[10px] inline-flex items-center">
        <div
          className="w-12 h-12 relative flex items-center justify-center"
          onClick={closeModelFunction}
        >
          <BackArrowIconSvg />
        </div>
        <div className="text-[#121212] text-2xl font-medium font-['Circular Std'] leading-[15px]">
          Create Wallet
        </div>
      </div>
      <InputField
        // icon={field.icon}
        label={"Account Number"}
        name={"account_number"}
        handelInputChanges={handelInputChanges}
        stateData={accountInfo}
        // key={`accountInfoFields`}
        type={"number"}
      />

      <SelectDropDownField
        label={"Select Bank"}
        optionData={banks?.map((bank) => bank.name)}
        className=" mt-3 max-w-[687px] w-full"
        stateData={accountInfo}
        // key={index}
        name={"bank"}
        handelSelectChanges={handelSelectChanges}
      />

      {/* {accountInfo?.account_number?.length <= 10 &&
      accountInfo?.selectedBank &&
      !accountInfo?.account_name ? (
        <span>Loading...</span>
      ) : (
        <span className="text-[20px] mt-2 mb-5">
          <b>Account Name:</b> {accountInfo?.account_name}
        </span>
      )} */}
      {!accountInfo?.account_number ||
      accountInfo?.account_number?.length < 10 ? (
        <span>Enter valid account number.</span>
      ) : !accountInfo?.selectedBank ? (
        <span>Select a bank.</span>
      ) : loading ? (
        <span>Loading...</span>
      ) : accountInfo?.account_name ? (
        <span className="text-[20px] mt-2 mb-5">
          <b>Account Name:</b> {accountInfo.account_name}
        </span>
      ) : (
        <span>No account name available.</span>
      )}

      {accountInfo?.account_name && (
        <>
          <InputField
            // icon={field.icon}
            label={"Withdrawal Pin"}
            name={"pin"}
            handelInputChanges={handelInputChanges}
            stateData={accountInfo}
            type={"password"}
          />
          {!accountInfo.pin || accountInfo.pin.length < 4 ? (
            <span>Enter valid account number.</span>
          ) : null}

          {!accountInfo.pin ||
            (accountInfo.pin.length >= 4 && (
              <button
                className="bg-[#F46702] flex items-center justify-center rounded-md h-[40px] px-5 text-white mt-5 "
                onClick={() => createAccountFunction()}
              >
                Create Wallet
              </button>
            ))}
        </>
      )}
    </div>
  );
}
