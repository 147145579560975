import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
// import { UserContext } from './UserContext'
import moment from "moment";
import { useAuthContext } from "./authContext";

export const DataContext = createContext();

function DataProvider({ children }) {
  const { accountType, endpoint, token, getProfileUpdateFunction } =
    useAuthContext();
  const [listedVehicle, setListedVehicle] = useState({
    loading: true,
    data: [],
  });
  const [ownerListedVehicle, setOwnerListedVehicle] = useState({
    loading: true,
    data: [],
  });
  const [bookedListedVehicle, setBookedListedVehicle] = useState({
    loading: true,
    data: [],
  });
  const [assignedVehicle, setAssignedVehicle] = useState({
    loading: true,
    data: [],
  });
  const [notificationList, setNotificationList] = useState({
    loading: true,
    data: [],
  });
  const [ProtectionPlans, setProtectionPlans] = useState({
    loading: true,
    data: [],
  });
  const [payments, setPayments] = useState({
    loading: true,
    data: [],
  });
  const [SearchFilter, setSearchFilter] = useState({
    key: "",
    type: "",
  });
  const paystackPublicKey = "pk_test_92b1b7eb4252a8e07614c90360e4412902ff8de4";

  const [vehicleDetail, setVehicleDetail] = useState({
    loading: true,
    data: {},
  });

  useEffect(() => {
    getAllVehicleFunction();
    if (!token) return;
    getNotificationFunction();
    getProfileUpdateFunction();
    if (accountType === "owner") {
      getOwnerVehicleFunction();
    }
    if (accountType === "rider") {
      getAssignedVehicleFunction();
      getBookedVehicleFunction();
      getPaymentsFunction();
    }
  }, [token]);

  function getAllVehicleFunction() {
    // console.log({ token });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ response });
        if (response.data.okay) {
          setListedVehicle({
            loading: false,
            data: response.data.vehicles,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getOwnerVehicleFunction() {
    // console.log({ token });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/owner`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ response });
        if (response.data.okay) {
          // localStorage.setItem(
          //   "vooom-admin-profile",
          //   JSON.stringify(response.data.profile)
          // );
          // setProfile(response.data.profile);
          setOwnerListedVehicle({
            loading: false,
            data: response.data.vehicles,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getBookedVehicleFunction() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/rider/book-inspection`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ response });
        if (response.data.okay) {
          // localStorage.setItem(
          //   "vooom-admin-profile",
          //   JSON.stringify(response.data.profile)
          // );
          // setProfile(response.data.profile);
          setBookedListedVehicle({
            loading: false,
            data: response.data.vehicles,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getAssignedVehicleFunction() {
    // console.log({ token });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/rider`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ response });
        if (response.data.okay) {
          // console.log(response);
          setAssignedVehicle({
            loading: false,
            data: response.data.vehicle,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getNotificationFunction() {
    // console.log({ token });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/notification`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ response });
        if (response.data.ok) {
          // console.log(response);
          setNotificationList({
            loading: false,
            data: response.data.notifications,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getProtectionPlansFunction() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/plans`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.okay) {
          setProtectionPlans({ loading: false, data: response.data.plans });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getPaymentsFunction() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/payment`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.okay) {
          // console.log({ payments: response.data.payments });
          setPayments({ loading: false, data: response.data.payments });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function cancelledInspectionFunction(vehicle_id) {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/rider/book-inspection?vehicle_id=${vehicle_id}`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log({ data: response.data });
        if (response.data.okay) {
          // setVehicleDetail({
          //   loading: false,
          //   data: response.data.vehicle,
          // });
          getVehicleDetailFunction(vehicle_id);
          getBookedVehicleFunction();
          toast.success("Vehicle Inspection Cancelled");
          getNotificationFunction();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getVehicleDetailFunction(carID) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle?vehicle_id=${carID}`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log({ data: response.data });
        if (response.data.okay) {
          setVehicleDetail({
            loading: false,
            data: response.data.vehicle,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handelBookVehicleInspectionFunction(vehicle_id) {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/rider/book-inspection?vehicle_id=${vehicle_id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: {},
    };

    try {
      let response = await axios.request(config);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function handelConfirmVehicleInspectionFunction({
    vehicle_id,
    payment_amount,
    payment_type,
    payment,
  }) {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle/rider/book-inspection?vehicle_id=${vehicle_id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: { payment_amount, payment_type, payment },
    };

    try {
      let response = await axios.request(config);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  // async function createWalletFunction() {
  //   let config = {
  //     method: "put",
  //     maxBodyLength: Infinity,
  //     url: `${endpoint}/vehicle/rider/book-inspection?vehicle_id=${vehicle_id}`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: token,
  //     },
  //     data: { payment_amount, payment_type, payment },
  //   };

  //   try {
  //     let response = await axios.request(config);
  //     return response;
  //   } catch (error) {
  //     return error.response;
  //   }
  // }

  return (
    <DataContext.Provider
      value={{
        paystackPublicKey,
        listedVehicle,
        getAllVehicleFunction,
        ownerListedVehicle,
        bookedListedVehicle,
        assignedVehicle,
        SearchFilter,
        setSearchFilter,
        notificationList,
        ProtectionPlans,
        getProtectionPlansFunction,
        cancelledInspectionFunction,
        vehicleDetail,
        getVehicleDetailFunction,
        handelBookVehicleInspectionFunction,
        handelConfirmVehicleInspectionFunction,
        getBookedVehicleFunction,
        getNotificationFunction,
        getPaymentsFunction,
        payments,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataProvider;

export const useDataContext = () => useContext(DataContext);
