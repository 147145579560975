import { useDisclosure } from "@chakra-ui/react";
import React, { createContext } from "react";
import { useEffect } from "react";

export const ModalContext = createContext();

function ModalProvider({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function closeModelFunction() {
    onClose();
  }
  function openModelFunction() {
    onOpen();
  }

  return (
    <ModalContext.Provider
      value={{
        isModalOpen: isOpen,
        closeModelFunction,
        openModelFunction,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
