import React, { useState, useEffect } from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../Context/authContext";
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

function LoginPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  let action = queryParams.get("action");

  useEffect(() => {
    if (!action || (action !== "owner" && action !== "rider")) {
      navigate("/auth/purpose?action=login");
    }
  }, [action]);
  return (
    <main className="flex overflow-hidden flex-col pb-56 bg-white max-md:pb-24 ">
      <div className="fixed t-0 w-full bg-white">
        <NavigationComponent />
      </div>
      <section className="flex flex-wrap gap-10 items-center self-center px-32 py-16 mt-36 rounded-2xl bg-neutral-50 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto tracking-tight min-w-[240px] text-neutral-900 w-[285px]">
          <div className="flex flex-col justify-center w-full">
            <h1 className="self-start text-3xl font-bold leading-none text-center">
              {action?.charAt(0).toUpperCase() + action?.slice(1)} Login
            </h1>
            <p className="mt-5 text-xl leading-6 font-[450]">
              Sign in as a
              {action === "owner"
                ? " vehicle owner to rent your vehicles."
                : " Rider to rent vehicles"}
            </p>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/65dc2bb475ea99ee85c5e518d79a4d538fd609fe1de19c3019b46ca4fbf9cbe9?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
            alt=""
            className="object-contain mt-20 max-w-full aspect-square w-[200px] max-md:mt-10"
          />
        </div>
        <div className="flex flex-col justify-center items-center self-stretch my-auto min-w-[240px] w-[420px]">
          <LoginForm loginAs={action} />
          {/* <SocialSignUp /> */}
          <p className="mt-7 text-xl leading-tight text-neutral-900">
            Register an account?{" "}
            <Link to={"/auth/register?action=" + action} className="underline">
              Sign Up
            </Link>
          </p>
        </div>
      </section>
    </main>
  );
}

export default LoginPage;

function LoginForm({ loginAs }) {
  const { loginAuthFunction } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState({
    email: "",
    password: "",
    accountType: "",
  });

  const handelChanges = (e) => {
    setAuth((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  async function handelLoginFunction() {
    const { email, password } = auth;
    setLoading(true);

    if (!auth.email || auth.email === "") {
      // Toast
      return toast.error("Email is Required");
    }
    if (!auth.password || auth.password === "") {
      // Toast
      return toast.error("Password is Required");
    }

    const loginResponce = await loginAuthFunction({
      email,
      password,
      accountType: loginAs,
    });
    if (!loginResponce) {
      setLoading(false);
      return toast.error("Network Error");
    }

    if (!loginResponce.data.okay) {
      setLoading(false);
      return toast.error(loginResponce.data.message);
    }

    const { token, profile } = loginResponce.data;
    console.log({ profile });
    localStorage.setItem("vooom-token", token);
    localStorage.setItem("vooom-profile", JSON.stringify(profile));
    window.location.href = "/dashboard";
    setLoading(false);
  }

  return (
    <form
      className="flex flex-col w-full text-base tracking-tight leading-none font-[450] max-w-[420px] text-neutral-900 text-opacity-70"
      onSubmit={(e) => {
        e.preventDefault();
        handelLoginFunction();
      }}
    >
      <div className="w-full rounded min-h-[72px] max-md:px-5 border border-[#121212] overflow-hidden">
        <input
          type="email"
          id="email"
          placeholder="Email address"
          className="size-full bg-white ps-5  min-h-[72px] rounded"
          required
          name="email"
          onChange={(e) => handelChanges(e)}
        />
      </div>
      <div className="w-full rounded min-h-[72px] max-md:px-5 mt-5 border border-[#121212] overflow-hidden">
        <input
          type="password"
          id="password"
          placeholder="Enter password"
          className="size-full bg-white ps-5  min-h-[72px] rounded"
          required
          name="password"
          onChange={(e) => handelChanges(e)}
        />
      </div>

      <button
        type="submit"
        disabled={loading}
        className="gap-2.5 self-stretch px-2.5 py-5 mt-20 w-full text-xl leading-none text-white bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] max-md:mt-10"
      >
        {loading ? <Spinner /> : "Login"}
      </button>
    </form>
  );
}

function SocialSignUp() {
  const socialButtons = [
    {
      name: "Google",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/533bb46a002fad655f87e55b4e6b79ee9ea9e78b00346cc7150f85d25f8dd896?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43",
    },
    {
      name: "Facebook",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1159f74c3437fc2e441b756c08d9da496c0416c134ac3b1f325df64a26ae8efc?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43",
    },
  ];

  return (
    <div className="flex flex-col w-full text-sm leading-loose font-medium tracking-tight text-center text-neutral-900">
      {socialButtons.map((button, index) => (
        <button
          key={index}
          className="flex gap-3 justify-center items-center px-2.5 py-5 mt-4 w-full rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)]"
        >
          <img
            loading="lazy"
            src={button.icon}
            alt=""
            className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
          />
          <span className="self-stretch my-auto">
            Sign up with {button.name}
          </span>
        </button>
      ))}
    </div>
  );
}
