import React, { useEffect, useState } from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";
import { useAuthContext } from "../../Context/authContext";
import {
  ChooseProtectionPlan,
  OwnerNationalIdentityForm,
  PersonalInformationForm,
  UserAddGuarantorForm,
  UserNationalIdentityForm,
} from "../AuthPage/AccountSetupPage";
import { useDataContext } from "../../Context/dataContext";

function ProfilePage() {
  const { profile, accountType, handleProfileUpdateFunction } =
    useAuthContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [pageSteps, setPageSteps] = useState([]);

  useEffect(() => {
    // NOTE: this is the owner profile sub navigation
    if (accountType === "owner") {
      return setPageSteps([
        {
          step: 1,
          label: "Personal information",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National identity",
          content: <OwnerNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Choose protection plan",
          content: <ChooseProtectionPlan action={nextStepFunction} />,
        },
      ]);
    }

    if (accountType === "rider") {
      return setPageSteps([
        {
          step: 1,
          label: "Personal information",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National identity",
          content: <UserNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Add guarantor",
          content: <UserAddGuarantorForm action={nextStepFunction} />,
        },
      ]);
    }
  }, [accountType]);

  function nextStepFunction(stepIndex) {
    // setCurrentStep(stepIndex);
  }

  return (
    <main className="flex overflow-hidden flex-col pb-56 bg-white max-md:pb-24 ">
      <div className="fixed t-0 w-full bg-white">
        <NavigationComponent />
      </div>
      <section className="mt-24 w-full  flex">
        <div className="w-[1128px] h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
          <div className="text-[#121212] text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Profile
          </div>
          <div className="justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-start gap-3 inline-flex">
              <div className="text-[#121212]/70 text-base font-['Circular Std'] leading-[13px]">
                {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}
              </div>
              <div className="text-[#121212] text-xl font-bold font-['Circular Std'] leading-[13px]">
                {profile?.full_name}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10 flex flex-col items-center  font-[450] mx-auto ">
        <div className="flex gap-5 w-full min-w-[480px]  justify-center">
          {pageSteps.map((step, index) => (
            <button
              className={`p-2 border-[2px] border-[#121212] rounded-md ${
                index === currentStep && "bg-[#F46702] text-white"
              }`}
              key={step.step}
              onClick={() => setCurrentStep(index)}
            >
              {step.label}
            </button>
          ))}
        </div>
        <div className="mt-0">{pageSteps[currentStep]?.content}</div>
      </section>
    </main>
  );
}

export default ProfilePage;
