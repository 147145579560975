import React, { useState } from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";

import {
  BikeIconSvg,
  CarIconSvg,
  DropDownArrowIconSvg,
  FilterSearchIconSvg,
  LorryIconSvg,
  VanIconSvg,
} from "../../icons";
import CarRentalCardComponent from "../../components/CarRentalCardComponent/CarRentalCardComponent";
import { Link } from "react-router-dom";

function SearchDetailPage() {
  const [searchResult, setSearchResult] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  return (
    <div className="SearchDetailPage">
      <section className="w-full mb-5">
        <NavigationComponent />
      </section>

      <section className="">
        <div className="inline-flex items-center justify-center gap-5 relative bg-white rounded-lg border-[0.8px] border-solid border-transparent">
          <div className="inline-flex flex-col items-start gap-3 relative flex-[0_0_auto]">
            <div className="relative w-fit mt-[-1.00px] [font-family:'Circular_Std-Bold',Helvetica] font-bold text-[#121212] text-[32px] tracking-[-0.41px] leading-9 whitespace-nowrap">
              Maraba
            </div>
            <div className="relative w-fit [font-family:'Circular_Std-Book',Helvetica] font-normal text-[#121212cc] text-xl tracking-[-0.41px] leading-6 whitespace-nowrap overflow-hidden text-ellipsis [display:-webkit-box] [-webkit-line-clamp:1] [-webkit-box-orient:vertical]">
              Federal capital territory, Abuja
            </div>
          </div>
          <div className="icon-container">
            <DropDownArrowIconSvg />
          </div>
        </div>
      </section>

      <section className="mt-3">
        {" "}
        <div className="relative flex justify-center  h-[76px] ">
          <div className="inline-flex flex-col items-end justify-center gap-3 me-0 absolute right-10 mt-[2.5px]">
            <div className="icon-container  ">
              <FilterSearchIconSvg />
            </div>

            <div className="relative font-normal text-[#121212] text-xl tracking-[-0.41px] leading-3">
              Filter search
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-wrap justify-center gap-10 my-10">
        {searchResult.map((car, index) => {
          return <CarRentalCardComponent index={index} key={index} />;
        })}
      </section>
    </div>
  );
}

export default SearchDetailPage;
