import { createBrowserRouter } from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage";
import SearchPage from "./SearchPage/SearchPage";
import SearchDetailPage from "./SearchPage/SearchDetailPage";
import VehicleDetailsPage from "./SearchPage/VehicleDetailsPage";
import SelectPurposePage from "./AuthPage/SelectPurposePage";
import LoginPage from "./AuthPage/LoginPage";
import AccountSetupPage from "./AuthPage/AccountSetupPage";
import EmailVerificationForm from "./AuthPage/EmailVerificationForm";
import HostVehiclePage from "./HostVehiclePage/HostVehiclePage";
import DashboardPage from "./DashboardPage/DashboardPage";
import RegisterPage from "./AuthPage/RegisterPage";
import ProfilePage from "./ProfilePage/ProfilePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
  {
    path: "/search/result",
    element: <SearchDetailPage />,
  },
  {
    path: "/search/:carID",
    element: <VehicleDetailsPage />,
  },
  {
    path: "/auth/purpose",
    element: <SelectPurposePage />,
  },
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/auth/register",
    element: <RegisterPage />,
  },
  {
    path: "/auth/otp",
    element: <EmailVerificationForm />,
  },

  // {
  //   path: "/dashboard",
  //   element: <DashboardPage />,
  // },
  {
    path: "/",
    // element: <YourApp />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/dashboard/host-vehicle",
        element: <HostVehiclePage />,
      },
      {
        path: "dashboard/vehicle/:carID",
        element: <VehicleDetailsPage />,
      },
      {
        path: "dashboard/setup-account",
        element: <AccountSetupPage />,
      },
      {
        path: "dashboard/profile",
        element: <ProfilePage />,
      },
      // Add more routes here
    ],
  },
]);
