import React from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";
import { SearchBarComponent } from "../../components/SearchFormComponent/SearchFormComponent";
import { Link, useNavigate } from "react-router-dom";
import { useDataContext } from "../../Context/dataContext";
import "./LandingPage.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function LandingPage() {
  const vehicleCategories = [
    {
      name: "Cars",
      key: "Car",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/b1f9164e6aeede3cd5097ceeb4cbfb500285e51f617b32f7ffb61835eba5468f?apiKey=e85d4038ac5a41748b51322169f61f43&",
    },
    {
      name: "Motorcycles",
      key: "Bike",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/ed10eadd1afe4baa79429f0af373ec0ea29de0c0b33d08fba6fe9333668b1f86?apiKey=e85d4038ac5a41748b51322169f61f43&",
    },
    {
      name: "SUV'S",
      key: "Lorry",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3d1127f388bc129931dffb8a61b0f4431eeb55243b7a70d52ea0e419e77a8209?apiKey=e85d4038ac5a41748b51322169f61f43&",
    },
    {
      name: "Vans",
      key: "Van",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/06f6b1637846731acde9d677a72f9b50c75288387752470490e2e82277f2e9f3?apiKey=e85d4038ac5a41748b51322169f61f43&",
    },
  ];
  return (
    <div className="border LandingPage">
      <main className="flex flex-col items-center pb-0 bg-white">
        <section className="w-full mb-5 z-20 fixed top-0 bg-white">
          <NavigationComponent />
        </section>

        <section className="hero-section mt-[100px] h-[850px] md:h-[1000px]">
          <div class="content w-full ">
            {/* <h1>Your Main Content</h1>
            <p>This text appears above the transparent overlay.</p> */}
            <h1 className="text-[60px] md:text-[96px] leading-[80px]  w-[80%]">
              Access Vehicles on your terms.
            </h1>
            <div className=" w-full h-[72px]  max-w-[646px]">
              <Link to={"/search"} className="w-full">
                <SearchBarComponent />
              </Link>
            </div>

            <div className="mt-10 md:mt-28 w-full">
              <HeroSectionCardSlideComponent
                images={[
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                  "https://archive.org/download/placeholder-image/placeholder-image.jpg",
                ]}
              />
            </div>
            {/* </div>
            </div> */}
          </div>
        </section>
        <h2 className="mt-16 text-3xl font-medium tracking-tight text-neutral-900 max-md:mt-10 max-md:max-w-full">
          Available now
        </h2>
        <section className="mt-12 w-full max-w-[1128px] max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-wrap justify-evenly gap-5  max-md:flex-col max-md:gap-0 mx-3">
            {vehicleCategories.map((category, index) => (
              <VehicleCategory
                key={index}
                name={category.name}
                image={category.image}
                filterKey={category.key}
              />
            ))}
          </div>
        </section>
        {/* <RentYourVehicle /> */}
        <section className="justify-center mt-40 w-full max-w-[1124px] max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col justify-center self-stretch px-5 my-auto tracking-tighter max-md:mt-10 max-md:max-w-full">
                <p className="text-2xl leading-7 font-[450] text-black text-opacity-80 max-md:max-w-full">
                  Rent your vehicle when you are not using it.
                </p>
                <h2 className="mt-5 text-5xl font-bold text-black leading-[52px] max-md:max-w-full max-md:text-4xl max-md:leading-10">
                  Earn passive income with your vehicle.
                </h2>
                <button className="justify-center items-center p-3.5 mt-10 text-xl font-medium tracking-tight leading-7 text-center text-white bg-orange-600 rounded-xl border border-solid shadow-sm border-black border-opacity-60 max-md:px-5 max-md:max-w-full">
                  Rent your vehicle
                </button>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/36a35f01c1bcc194b1f3ab827f1bead10ee76ea27d412167b2fca14a850e191f?apiKey=e85d4038ac5a41748b51322169f61f43&"
                alt="Person renting out their vehicle"
                className="grow w-full aspect-[1.79] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </section>
        <section className="justify-center mt-40 w-full max-w-[1124px] max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-row-reverse gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col justify-center self-stretch px-5 my-auto tracking-tighter max-md:mt-10 max-md:max-w-full">
                <p className="text-2xl leading-7 font-[450] text-black text-opacity-80 max-md:max-w-full">
                  Rent your vehicle when you are not using it.
                </p>
                <h2 className="mt-5 text-5xl font-bold text-black leading-[52px] max-md:max-w-full max-md:text-4xl max-md:leading-10">
                  Earn passive income with your vehicle.
                </h2>
                <button className="justify-center items-center p-3.5 mt-10 text-xl font-medium tracking-tight leading-7 text-center text-white bg-orange-600 rounded-xl border border-solid shadow-sm border-black border-opacity-60 max-md:px-5 max-md:max-w-full">
                  Rent your vehicle
                </button>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/36a35f01c1bcc194b1f3ab827f1bead10ee76ea27d412167b2fca14a850e191f?apiKey=e85d4038ac5a41748b51322169f61f43&"
                alt="Person renting out their vehicle"
                className="grow w-full aspect-[1.79] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </section>
        <FooterComponent />
      </main>
    </div>
  );
}

export default LandingPage;

const VehicleCategory = ({ filterKey, name, image }) => {
  const navigate = useNavigate();

  const { setSearchFilter } = useDataContext();
  return (
    <div
      className="flex flex-col w-[48%] max:w-[539px] max-md:ml-0 max-md:w-full"
      onClick={() => {
        setSearchFilter((prev) => {
          return {
            ...prev,
            type: filterKey,
          };
        });
        navigate("/search");
      }}
    >
      <div className="flex flex-col grow p-3 w-full text-2xl font-bold tracking-tight leading-7 text-center whitespace-nowrap bg-white rounded-xl border border-solid border-neutral-400 text-neutral-900 max-md:mt-10 max-md:max-w-full">
        <h3 className="max-md:max-w-full">{name}</h3>
        <img
          loading="lazy"
          src={image}
          alt={`${name} category`}
          className="mt-5 w-full aspect-[2.38] max-md:mr-2.5 max-md:max-w-full rounded-[10.592px]"
        />
      </div>
    </div>
  );
};

function HeroSectionCardSlideComponent({ images }) {
  return (
    <div className="HeroSectionCardSlideComponent ">
      <div class="scroll-content">
        {images.map((image, index) => (
          <div
            className="image-container h-[140px] min-w-[180px] md:h-[225px] md:min-w-[313px]"
            key={index}
          >
            <div className="size-full cursor-pointer overflow-hidden rounded">
              <img
                src={image}
                alt={`Car exterior ${index + 1}`}
                className="object-cover object-center size-full"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function FooterComponent() {
  return (
    <footer className="FooterComponent mt-10 bg-[#2E2E2E] w-full flex-col md:flex-row flex justify-evenly p-[20px] md:p-[64px] m-0 ">
      <div className="flex gap-10 md:gap-20 flex-wrap">
        <div className="w-[200px]">
          <h3>Menu</h3>
          <ul>
            <li> Contact us</li>
            <li>Team</li>
            <li>Careers</li>
            <li>
              <Link
                to={
                  "https://docs.google.com/forms/d/e/1FAIpQLSePBWb71n4SdOP8BTOchBvSMFQ5eTh7_leK-ES8nRxF63BtNQ/viewform?usp=sf_link"
                }
              >
                Partners
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-[200px]">
          <h3>Legal</h3>
          <ul>
            <li>Papers and Insurance</li>
            <li>Trust and safety</li>
            <li>Policies</li>
            <li>Terms of use</li>
          </ul>
        </div>
        <div className="w-[200px]">
          <h3>Support</h3>
          <ul>
            <li>Policies</li>
            <li>Customer support</li>
            <li>Frequently asked questions</li>
            <li>How it works</li>
          </ul>
        </div>
      </div>
      <div className="md:border-l-[2px] md:ps-[10%]">
        <h3 className="text-white">Follow our socials</h3>

        <div className="icon-container flex gap-5 mt-5">
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M17.5078 6.5H17.4988"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 21L10.5484 13.4516M10.5484 13.4516L3 3H8L13.4516 10.5484M10.5484 13.4516L16 21H21L13.4516 10.5484M21 3L13.4516 10.5484"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M10.5359 11.0104C9.71585 10.8945 7.84666 11.0863 6.93011 12.7811C6.01355 14.4758 6.9373 16.2397 7.51374 16.9098C8.08298 17.5367 9.89226 18.7239 11.8114 17.5648C12.2871 17.2775 12.8797 17.0632 13.552 14.8182L13.4738 5.98438C13.3441 6.95711 14.4186 9.23868 17.478 9.50863"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="mt-10">
          <span>Copyright © 2024 Vooom </span>
          <img
            src={require("../../assets/image/white-logo.png")}
            alt="footer_logo"
            className="w-[120px]"
          />
        </div>
      </div>
    </footer>
  );
}
