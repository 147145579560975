import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../Context/authContext";
import {
  CreateAccountIconSvg,
  DashboardIconSvg,
  LoginIconSvg,
  LogoutIonSvg,
} from "../../icons";

function NavigationComponent() {
  const { endpoint, token, accountType } = useAuthContext();
  // useEffect(() => {
  //   console.log({ endpoint, token });
  // }, []);
  return (
    <header className="flex gap-5 justify-between items-center self-stretch px-10 py-4 w-full border-b border-solid border-neutral-900 border-opacity-70 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
      <Link to="/">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c7e4693bea7682db8585fc8823e9a4da6fcc4b3350fb87f462fb0b67c77451b?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt="Company logo"
          className="shrink-0 my-auto max-w-full mix-blend-difference aspect-[3.85] w-[100px] md:w-[151px] bg-black"
        />
      </Link>
      <div className="hid den flex gap-5 justify-center px-3 py-2 rounded border border-solid border-neutral-900 border-opacity-70">
        {accountType ? (
          <>
            {accountType !== "owner" ? (
              <Link
                to={"/search"}
                className="my-auto text-base font-bold tracking-tight leading-3 text-neutral-900"
              >
                Explore vehicles
              </Link>
            ) : (
              <Link
                to={"/dashboard/host-vehicle"}
                className="my-auto text-base font-bold tracking-tight leading-3 text-neutral-900"
              >
                Host your vehicle
              </Link>
            )}
          </>
        ) : (
          <Link
            to={accountType === "owner" ? "/host-vehicle" : "/auth/login"}
            className="my-auto text-base font-bold tracking-tight leading-3 text-neutral-900"
          >
            Host your vehicle
          </Link>
        )}
        <NavigationMenuButtonComponent isLogin={token} />
      </div>
    </header>
  );
}

export default NavigationComponent;

function NavigationMenuButtonComponent({ isLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [navigationLink, setNavigationLink] = useState([]);
  const { logoutFunction } = useAuthContext();

  useEffect(() => {
    if (isLogin) {
      setNavigationLink([
        // { linkUrl: "./#", icon: "", name: "Notifications" },
        // { linkUrl: "./#", icon: "", name: "Guarantor" },
        // { linkUrl: "./#", icon: "", name: "Privacy policy" },
        // { linkUrl: "./#", icon: "", name: "Protection plan" },
        // { linkUrl: "./#", icon: "", name: "Referrals" },
        // { linkUrl: "./#", icon: "", name: "Customer support" },
      ]);
    } else {
      setNavigationLink([
        {
          linkUrl: `/auth/purpose?action=register`,
          icon: <CreateAccountIconSvg />,
          name: "Create an account",
        },
        {
          linkUrl: `/auth/purpose?action=login`,
          icon: <LoginIconSvg />,
          name: "Login",
        },
      ]);
    }
  }, [isOpen]);

  return (
    <div
      className="NavigationMenuButtonComponent cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        // to={"/auth/login"}
        className="flex gap-1.5 justify-center p-1.5 rounded bg-zinc-100"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1271845cdb073f630f55374e515a3e66de6617c2a9033c3642886de3045a13f?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt=""
          className="shrink-0 my-auto w-5 aspect-square"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d10246d2bd3ca71d0440d5a9396e9db9c0643e2869de49756c488752c948d462?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt=""
          className="shrink-0 w-6 aspect-square"
        />
      </div>

      {isOpen && (
        <div
          className={`z-[1100]  bg-white shadow absolute w-[250px]  w -[323px] h-auto right-5 mt-[20px] p-[20px] gap-[60px] rounded-[8px] `}
        >
          {isLogin && (
            <Link
              to={"/dashboard"}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer border-b-1"
            >
              <div className="icon w-[24px] h-[24px] ">
                {" "}
                <DashboardIconSvg />
              </div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                Dashboard
              </div>
            </Link>
          )}

          {navigationLink.map((link, i) => (
            <Link
              to={link.linkUrl}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer "
              key={i}
            >
              <div className="icon w-[24px] h-[24px] ">{link.icon}</div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                {link.name}
              </div>
            </Link>
          ))}

          {isLogin && (
            <div
              to={"/dashboard"}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer border-b-1 text-red-900 hover:bg-red-200"
              onClick={() => logoutFunction()}
            >
              <div className="icon w-[24px] h-[24px] ">
                <LogoutIonSvg />
              </div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                Logout
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
