import React from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";

function EmailVerificationForm() {
  return (
    <main className="flex overflow-hidden flex-col pb-96 bg-white max-md:pb-24">
      <div className="fixed bg-white top-0 w-full">
        {" "}
        <NavigationComponent />
      </div>
      <section className="flex flex-wrap gap-10 items-center self-center px-32 py-16 mt-80 rounded-2xl border-2 border-solid bg-neutral-50 border-neutral-400 border-opacity-50 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col justify-center self-stretch my-auto tracking-tight min-w-[240px] text-neutral-900">
          <h1 className="self-start text-3xl font-bold leading-none text-center">
            Verify email address
          </h1>
          <p className="mt-5 text-xl leading-6 font-[450]">
            A 4 digit code has been sent to your email address.
          </p>
        </div>
        <form className="flex flex-wrap gap-7 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
          {[...Array(4)].map((_, index) => (
            <VerificationInput key={index} />
          ))}
        </form>
      </section>
    </main>
  );
}

export default EmailVerificationForm;

function VerificationInput() {
  return (
    <input
      type="text"
      maxLength="1"
      aria-label="Verification code digit"
      className="flex shrink-0 gap-10 self-stretch my-auto rounded-md border-4 border-solid border-neutral-900 border-opacity-70 h-[84px] w-[104px] \\ text-[40px] text-[#121212b2] text-center font-normal tracking-[-0.57px] leading-[30.6px] whitespace-nowrap"
    />
  );
}
