import React, { useContext, useEffect, useState } from "react";
import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";
import { UploadInputField } from "../AuthPage/AccountSetupPage";
import ModelPopupComponent from "../../components/ModelPopupComponent/ModelPopupComponent";
import LocationInput from "../../components/LocationInputComponent/LocationInputComponent";

import CongratulationsImage from "../../assets/image/CongratulationsImage.png";
import { ModalContext } from "../../Context/modalContext";
import { toast } from "react-toastify";
import { useAuthContext } from "../../Context/authContext";
import axios from "axios";
import { useDataContext } from "../../Context/dataContext";
import { Link } from "react-router-dom";
// import CongratulationsImage from "../../";
function HostVehiclePage() {
  const { openModelFunction } = useContext(ModalContext);
  const {} = useDataContext();
  const [currentStep, setCurrentStep] = useState(0);
  const { token, endpoint } = useAuthContext();
  const progressSteps = [
    {
      step: 1,
      label: "Personal information",
      content: <VehicleHostingForm action={nextStepFunction} />,
    },
    {
      step: 2,
      label: "National identity",
      content: <VehicleFeaturesForm action={nextStepFunction} />,
    },
    {
      step: 3,
      label: "Choose protection plan",
      content: <UploadCarImageForm action={handelCreateVehicleFunction} />,
    },
  ];

  const [hostVehicleFormData, setHostVehicleFormData] = useState({});

  function handelCreateVehicleFunction(formInformationData) {
    if (formInformationData) {
      console.log({ formInformationData });
      setHostVehicleFormData({
        ...hostVehicleFormData,
        ...formInformationData,
      });
    }

    console.log({ hostVehicleFormData });
    let data = JSON.stringify(hostVehicleFormData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle`,
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setHostVehicleFormData({});
        openModelFunction();
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log({ hostVehicleFormData });
  }

  function nextStepFunction(stepIndex, formInformationData) {
    if (formInformationData) {
      setHostVehicleFormData({
        ...hostVehicleFormData,
        ...formInformationData,
      });
    }
    console.log({ hostVehicleFormData });
    setCurrentStep(stepIndex);
  }

  return (
    <main className="flex overflow-hidden flex-col pb-56 bg-white max-md:pb-24 ">
      <div className="fixed z-50 t-0 w-full bg-white">
        <NavigationComponent />
      </div>
      <section className="flex flex-wrap gap-10 mt-40 items-center justify-center">
        <ModelPopupComponent trigger={<></>}>
          <CongratulationModelBody />
        </ModelPopupComponent>
        {progressSteps[currentStep]?.content}
      </section>
    </main>
  );
}

export default HostVehiclePage;

function VehicleHostingForm({ action }) {
  const [vehicleInfo, setVehicleInfo] = useState({});

  function handelInputChanges(e) {
    // console.log(e.target.name, e.target.value);
    setVehicleInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  const handleLocationSelect = (place) => {
    // Assuming you want to save the selected place's details
    setVehicleInfo((prev) => ({
      ...prev,
      pick_up_location: place.description, // or any other property you want to save
      location_lat: place.geometry.location.lat(),
      location_lng: place.geometry.location.lng(),
    }));
  };

  function handelSelectChanges(name, value) {
    setVehicleInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const vehicleInfoFields = [
    {
      label: "Location",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ff5ee4ea1f046d7091e8aa945676da93bc80fc09026d068c5aee051f11d42bb?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      // optionData: ["Car", "Truck", "Motorcycle", "SUV"],
      name: "pick_up_location",
      optionData: ["FCT Abuja", "Lagos"],
    },
    {
      label: "Vehicle type",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e976569a02bfe8d3bbc277d278da78a5b0de5d9a8e600b368bf27487bbbc8532?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["Car", "Truck", "Motorcycle", "SUV"],
      name: "vehicle_type",
    },
    {
      label: "Vehicle Company",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8817e337f08e011aebe2e8256fd86234b8db663315e00994a6c9f92bf61bcd54?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: [
        "Aston martin",
        "Audi",
        "Chevrolet",
        "Ford",
        "Golf",
        "Honda",
        "Hyundai",
        "Mazda",
        "Mercedes benz",
        "Peugeot",
        "Volkswagen",
      ],
      name: "vehicle_company",
    },

    {
      label: "Name of vehicle",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e976569a02bfe8d3bbc277d278da78a5b0de5d9a8e600b368bf27487bbbc8532?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["Sonata", "Accent", "Elantra", "Sonata", "Vellocster"],
      name: "vehicle_name",
    },
    {
      label: "Model",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49fd9e7c5094afe1a7654815a7d3d487f926263b983738cd2fc1f7eb8c351066?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["2012", "2013", "2014", "2015"],
      name: "model",
    },

    {
      label: "Timeline available for rent",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49fd9e7c5094afe1a7654815a7d3d487f926263b983738cd2fc1f7eb8c351066?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: [
        "Long term | Commercial use",
        "Working hours (10am – 4pm)",
        "Night time (7pm – 7am)",
      ],
      name: "timeline_rent",
    },
    {
      label: "Distance",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49fd9e7c5094afe1a7654815a7d3d487f926263b983738cd2fc1f7eb8c351066?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["100km", "150km", "200km", "300km"],
      name: "distance_allowed",
    },
  ];
  const {
    pick_up_location,
    vehicle_type,
    vehicle_company,
    vehicle_name,
    model,
    timeline_rent,
    // available_date,
    distance_allowed,
  } = vehicleInfo;
  const requiredProperties = [
    pick_up_location,
    vehicle_type,
    vehicle_company,
    vehicle_name,
    model,
    timeline_rent,
    // available_date,
    distance_allowed,
  ];
  return (
    <main className="flex flex-col px-0.5 max-w-[689px]">
      <section className="flex flex-col max-md:max-w-full">
        <h1 className="text-3xl font-medium tracking-tight leading-none text-neutral-900 max-md:max-w-full">
          Host your vehicle
        </h1>
        <form className="flex flex-col justify-center items-center mt-10 w-full text-xl max-md:max-w-full">
          <div className="flex flex-col justify-center items-center w-full tracking-tight leading-none font-[450] max-w-[691px] text-neutral-900 text-opacity-70 max-md:max-w-full">
            {vehicleInfoFields.map((field, index) => (
              <>
                {/*TODO: Change to backend options */}
                {field.type === "input" ? (
                  <InputField
                    icon={field.icon}
                    label={field.label}
                    name={field?.name}
                    handelInputChanges={handelInputChanges}
                    stateData={vehicleInfo}
                    key={`vehicleInfoFields${index}`}
                  />
                ) : (
                  <SelectDropDownField
                    label={field.label}
                    optionData={field.optionData}
                    className="mt-16 max-md:mt-10 max-w-[687px] w-full"
                    stateData={vehicleInfo}
                    key={index}
                    name={field?.name}
                    handelSelectChanges={handelSelectChanges}
                  />
                )}
              </>
            ))}
          </div>
          <button
            type="button"
            onClick={() => {
              // console.log({ vehicleInfo });

              const missingProperties = requiredProperties.filter(
                (property) => {
                  console.log(property);
                  return !property;
                }
              );

              if (missingProperties.length > 0) {
                return toast.error(`Fill the required input`);
              }
              action(1, vehicleInfo);
            }}
            className="gap-3 self-stretch px-3 py-4 mt-20 max-w-full font-medium tracking-tight leading-none text-center text-white whitespace-nowrap bg-orange-600 rounded-xl border border-solid border-black border-opacity-60 min-h-[60px] shadow-[2px_2px_9px_rgba(0,0,0,0.2)] w-[420px] max-md:mt-10"
          >
            Next
          </button>
        </form>
      </section>
    </main>
  );
}

export function InputField({
  label,
  icon,
  className = "",
  handelInputChanges,
  stateData,
  name,
  type,
  maxLength,
}) {
  return (
    <label
      className={`flex overflow-hidden flex-wrap gap-4 items-center px-7 py-7 w-full whitespace-nowrap rounded-md border border-solid border-neutral-900 min-h-[84px] max-md:px-5 ${className}`}
    >
      {icon && (
        <img
          loading="lazy"
          src={icon}
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-[0.83]"
        />
      )}
      <div className="self-stretch my-auto">
        {type === "password"
          ? stateData[name]?.length > 0 // Check if the password length is greater than 0
            ? "*".repeat(stateData[name].length) // Show asterisks if there's a password
            : label // Show the label if the input is empty
          : stateData[name] || label}{" "}
        {/* // Show actual value or label */}
      </div>
      <input
        type={type || "text"}
        aria-label={label}
        className="sr-only border"
        name={name}
        value={stateData[name]}
        // value={""}
        onChange={(e) => {
          // console.log(e.target.value);
          handelInputChanges(e);
        }}
      />
    </label>
  );
}

export function SelectDropDownField({
  name,
  handelSelectChanges,
  label,
  optionData,
  className,
  stateData,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  useEffect(() => {
    // if (!isOpen) return;
    if (selectedOptionIndex === null) {
      handelSelectChanges(name, "");
      return;
    }
    handelSelectChanges(name, optionData[selectedOptionIndex]);
  }, [selectedOptionIndex]);
  return (
    <div className={`relative -z-1 w-full ${className}  cursor-pointer`}>
      <div
        className={`flex z-10 overflow-hidden flex-wrap gap-4 items-center px-7 py-7 w-full whitespace-nowrap rounded-md border border-solid border-neutral-900 min-h-[84px] max-md:px-5 `}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          loading="lazy"
          src={
            "https://cdn.builder.io/api/v1/image/assets/TEMP/8817e337f08e011aebe2e8256fd86234b8db663315e00994a6c9f92bf61bcd54?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43"
          }
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-[0.83]"
        />
        <div
          className={`${selectedOptionIndex !== null && "text-neutral-950"}`}
        >
          {selectedOptionIndex === null ? (
            <>Select {label}...</>
          ) : (
            <>{stateData[name]}</>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute  z-[200] border border-solid border-neutral-900 border-t-0  w-full p-2 bg-white max-h-[400px] overflow-y-scroll">
          <div
            className="p-2  cursor-pointer mb-2"
            onClick={() => {
              setSelectedOptionIndex(null);
              setIsOpen(false);
            }}
          >
            Select {label}...
          </div>
          {optionData.map((option, index) => (
            <div
              className="p-2  cursor-pointer mb-2"
              key={index}
              onClick={() => {
                setSelectedOptionIndex(index);
                setIsOpen(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function VehicleFeaturesForm({ action }) {
  const features = [
    {
      label: "Gear transmission",
      options: [
        {
          label: "Automatic",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/e4b8c1baaa4d1b6d4690f35d99162554ec50beb6a7127eea1ef180a4bbecdc22?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
        { label: "Manual", selected: false },
      ],
      name: "gear_transmission",
    },
    {
      label: "Bluetooth",
      options: [
        {
          label: "Yes",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/e4b8c1baaa4d1b6d4690f35d99162554ec50beb6a7127eea1ef180a4bbecdc22?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
        { label: "No", selected: false },
      ],
      name: "bluetooth",
    },
    {
      label: "Android auto",
      options: [
        {
          label: "Yes",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/e4b8c1baaa4d1b6d4690f35d99162554ec50beb6a7127eea1ef180a4bbecdc22?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
        { label: "No", selected: false },
      ],

      name: "android_auto",
    },
    {
      label: "Rear camera",
      options: [
        {
          label: "Yes",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/e4b8c1baaa4d1b6d4690f35d99162554ec50beb6a7127eea1ef180a4bbecdc22?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
        { label: "No", selected: false },
      ],
      name: "rear_camera",
    },
    {
      label: "AUX input",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/4e3f77c40a11cf29bff097058e3bd16919b4ca27ca70dfabee5aa9bd23df0c6f?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
      ],
      name: "aux_in",
    },
    {
      label: "GPS",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/4e3f77c40a11cf29bff097058e3bd16919b4ca27ca70dfabee5aa9bd23df0c6f?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
      ],
      name: "gps",
    },
    {
      label: "USB input",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/4e3f77c40a11cf29bff097058e3bd16919b4ca27ca70dfabee5aa9bd23df0c6f?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
      ],
      name: "usb_in",
    },
    {
      label: "USB output",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/4e3f77c40a11cf29bff097058e3bd16919b4ca27ca70dfabee5aa9bd23df0c6f?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
        },
      ],
      name: "usb_out",
    },
  ];

  const [vehicleHealthInfo, setVehicleHealthInfo] = useState({});
  const [vehicleFeatureInfo, setVehicleFeatureInfo] = useState({});

  function handelInputChanges(e) {
    if (e.target.type === "radio") {
      const selectedValue = e.target.value === "True";
      setVehicleFeatureInfo((prev) => {
        return {
          ...prev,
          [e.target.name]: selectedValue,
        };
      });
    } else {
      setVehicleHealthInfo((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    }
  }
  const { body_exterior, engine, additional_note } = vehicleHealthInfo;
  const requiredProperties = [
    // pick_up_location,
    // vehicle_type,
    // vehicle_company,
    // vehicle_name,
    // model,
    // timeline_rent,
    // available_date,
    // distance_allowed,
    // features,
    // health_status,
    // gear_transmission,
    // gps,
    // rear_camera,
    // bluetooth,
    // usb_in,
    // usb_out,
    // aux_in,
    // android_auto,
    body_exterior,
    engine,
    additional_note,
  ];

  return (
    <main className="flex flex-col tracking-tight">
      <h1 className="text-3xl font-medium leading-none text-neutral-900 max-md:max-w-full">
        {/* <button className="mr-4" onClick={() => action(0)}>
          {"<-"}
        </button> */}
        Host your vehicle
      </h1>
      <section className="flex flex-col justify-center items-center mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col w-full max-md:max-w-full">
          <h2 className="gap-2.5 self-stretch py-2.5 w-full text-2xl font-medium leading-none whitespace-nowrap text-neutral-900 max-md:max-w-full">
            Features
          </h2>
          <div className="flex flex-wrap gap-16 items-center self-center mt-8 max-w-full text-xl leading-none w-[1120px]">
            {features.map((feature, index) => (
              <FeatureToggle
                key={index}
                label={feature.label}
                options={feature.options}
                name={feature.name}
                handelInputChanges={handelInputChanges}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col self-stretch mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <h2 className="gap-2.5 self-stretch py-2.5 w-full text-2xl font-medium leading-none text-neutral-900 max-md:max-w-full">
              Health status
            </h2>
            <div className="flex flex-col mt-8 w-full max-md:max-w-full">
              {/*  */}
              <div className="flex flex-col mt-10 w-full max-md:max-w-full">
                <label
                  htmlFor={"Body-and-exterior"}
                  className="text-xl font-medium leading-none text-neutral-900 max-md:max-w-full"
                >
                  {"Body and exterior"}
                </label>
                <textarea
                  id={"Body-and-exterior"}
                  className="overflow-hidden px-3 pt-3 pb-16 mt-5 w-full text-base leading-none bg-white rounded-md border-2 border-solid border-neutral-400 border-opacity-80 font-[450] shadow-[2px_2px_13px_rgba(244,103,2,0.19)] text-neutral-900 text-opacity-70 max-md:pr-5 max-md:max-w-full"
                  placeholder={"Body and exterior"}
                  name={"body_exterior"}
                  value={vehicleHealthInfo.body_exterior}
                  onChange={handelInputChanges}
                ></textarea>
              </div>
              {/*  */}

              {/*  */}
              <div className="flex flex-col mt-10 w-full max-md:max-w-full">
                <label
                  htmlFor={"Engine"}
                  className="text-xl font-medium leading-none text-neutral-900 max-md:max-w-full"
                >
                  Engine
                </label>
                <textarea
                  id={"Engine"}
                  className="overflow-hidden px-3 pt-3 pb-16 mt-5 w-full text-base leading-none bg-white rounded-md border-2 border-solid border-neutral-400 border-opacity-80 font-[450] shadow-[2px_2px_13px_rgba(244,103,2,0.19)] text-neutral-900 text-opacity-70 max-md:pr-5 max-md:max-w-full"
                  placeholder={"Engine"}
                  name={"engine"}
                  value={vehicleHealthInfo.engine}
                  onChange={handelInputChanges}
                ></textarea>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="flex flex-col mt-10 w-full max-md:max-w-full">
            <label
              htmlFor={"additional-note"}
              className="text-xl font-medium leading-none text-neutral-900 max-md:max-w-full"
            >
              Additional note:
            </label>
            <textarea
              id={"additional note"}
              className="overflow-hidden px-3 pt-3 pb-16 mt-5 w-full text-base leading-none bg-white rounded-md border-2 border-solid border-neutral-400 border-opacity-80 font-[450] shadow-[2px_2px_13px_rgba(244,103,2,0.19)] text-neutral-900 text-opacity-70 max-md:pr-5 max-md:max-w-full"
              placeholder={"I have standby fuel you can pay for."}
              name={"additional_note"}
              value={vehicleHealthInfo.additional_note}
              onChange={handelInputChanges}
            ></textarea>
          </div>
          {/*  */}
        </div>
        <button
          className="gap-3  px-3 py-4 mt-20 mx-auto  text-xl font-medium tracking-tight leading-none text-center text-white bg-orange-600 rounded-xl border border-solid border-black border-opacity-60 min-h-[60px] shadow-[2px_2px_9px_rgba(0,0,0,0.2)] w-[420px] max-md:mt-10"
          onClick={() => {
            const missingProperties = requiredProperties.filter((property) => {
              // console.log(property);
              return !property;
            });

            if (missingProperties.length > 0) {
              return toast.error(`Fill the required input`);
            }
            action(2, {
              features: vehicleFeatureInfo,
              health_status: vehicleHealthInfo,
            });
          }}
        >
          Next
        </button>
      </section>
    </main>
  );
}

function FeatureToggle({ name, label, handelInputChanges }) {
  return (
    <div className="flex flex-col grow shrink self-stretch py-5 my-auto min-w-[240px] w-[318px]">
      <div className="font-medium text-neutral-900">{label}</div>
      <div className="flex gap-3 items-start mt-6 whitespace-nowrap font-[450] text-neutral-900 text-opacity-80">
        <label className={`flex gap-10 p-5 rounded-md  w-[193px]`}>
          <div className="my-auto">True</div>
          <input
            type="radio"
            name={name}
            value={"True"}
            className="my-auto"
            onChange={handelInputChanges}
          />
        </label>
        <label className={`flex gap-10 p-5 rounded-md  w-[193px]`}>
          <div className="my-auto">False</div>
          <input
            type="radio"
            name={name}
            value={"False"}
            className="my-auto"
            onChange={handelInputChanges}
          />
        </label>
      </div>
    </div>
  );
}

export function ImageUploadInput({ placeholder, onImageUpload, multiple }) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'ostkv0vd');

    try {
      setLoading(true);
      const response = await axios.post(`https://api.cloudinary.com/v1_1/diki2xwvj/image/upload`, formData, {
        onUploadProgress: (event) => {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setProgress(percentCompleted);
        },
      });
      setLoading(false);
      return response.data.secure_url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
      return null; // Return null in case of an error
    }
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    if (files) {
      if (multiple) {
        // Handle multiple file uploads
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const url = await uploadImage(file);
          if (url) uploadedImages.push(url); // Collect successful uploads
        }
        onImageUpload(uploadedImages); // Pass all uploaded URLs back to parent
      } else {
        // Handle single file upload
        const file = files[0];
        const url = await uploadImage(file);
        onImageUpload(url); // Pass the uploaded image URL back to parent
      }
    }
  };

  return (
    <>
      <label
        htmlFor={placeholder.toLowerCase().replace(/\s+/g, "-")}
        className="flex gap-2 justify-center items-center py-8 mt-5 w-full font-medium tracking-tight leading-7 rounded-lg text-neutral-900 max-md:px-5 border border-dashed border-[#7c7c7c] cursor-pointer"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a9917c3fe2a46b9166750538f2436cc08df722b464f7dc9c96556276d830dd1?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
        {placeholder}
        <input
          type="file"
          id={placeholder.toLowerCase().replace(/\s+/g, "-")}
          className="sr-only"
          accept="image/*"
          onChange={handleImageChange} // Attach the onChange handler
          multiple={multiple} // Allow multiple uploads if specified
        />
      </label>
      {loading && <p>Uploading {placeholder}... {progress}%</p>}
    </>
  );
}

function UploadCarImageForm({ action }) {
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const [interiorFrontImage, setInteriorFrontImage] = useState('');
  const [interiorBackImage, setInteriorBackImage] = useState('');
  const [extraImages, setExtraImages] = useState([]);

  const handleExtraImagesUpload = (urls) => {
    setExtraImages(urls); // Set uploaded URLs for extra images
  };

  const handleSubmit = () => {
    action({
      vehicle_images: [
        frontImage,
        backImage,
        interiorFrontImage,
        interiorBackImage,
        ...extraImages,
      ],
    });
  };

  return (
    <main className="flex flex-col tracking-tight">
      <h1 className="text-3xl font-medium leading-none text-neutral-900 max-md:max-w-full">
        Host your vehicle
      </h1>
      <div className="min-w-[607px] m-3 inline-flex flex-col">
        <ImageUploadInput 
          placeholder="Front Image" 
          onImageUpload={setFrontImage} 
        />
        <ImageUploadInput 
          placeholder="Back Image" 
          onImageUpload={setBackImage} 
        />
        <ImageUploadInput 
          placeholder="Interior Front Image" 
          onImageUpload={setInteriorFrontImage} 
        />
        <ImageUploadInput 
          placeholder="Interior Back Image" 
          onImageUpload={setInteriorBackImage} 
        />
        <ImageUploadInput 
          placeholder="Extra Images" 
          multiple 
          onImageUpload={handleExtraImagesUpload} 
        />
        
        <div className="md:flex gap-10">
          {frontImage && <img src={frontImage} alt="Front" className="w-32 h-32 object-cover" />}
          {backImage && <img src={backImage} alt="Back" className="w-32 h-32 object-cover" />}
          {interiorFrontImage && <img src={interiorFrontImage} alt="Interior Front" className="w-32 h-32 object-cover" />}
          {interiorBackImage && <img src={interiorBackImage} alt="Interior Back" className="w-32 h-32 object-cover" />}
          {extraImages.map((image, index) => (
            <img key={index} src={image} alt={`Extra ${index}`} className="w-32 h-32 object-cover" />
          ))}
        </div>
        <button
          onClick={handleSubmit}
          className="gap-3 self-stretch px-3 py-4 mt-20 w-full text-xl font-medium tracking-tight leading-none text-center text-white bg-orange-600 rounded-xl border border-solid border-black border-opacity-60 min-h-[60px] shadow-[2px_2px_9px_rgba(0,0,0,0.2)] max-md:mt-10"
        >
          Host vehicle
        </button>
      </div>
    </main>
  );
}


function CongratulationModelBody() {
  const { isModalOpen, closeModelFunction, openModelFunction } =
    useContext(ModalContext);
  return (
    <div className="h-[583.30px] px-[100px] py-20 bg-white rounded-xl shadow border border-[#666666]/80 flex-col justify-start items-start gap-12 inline-flex">
      <div className="self-stretch h-[315.30px] flex-col justify-start items-center gap-8 flex">
        <img className="w-60 h-[211.30px]" src={CongratulationsImage} />
        <div className="self-stretch h-[72px] flex-col justify-start items-center gap-4 flex">
          <div className="self-stretch text-center text-[#121212] text-[32px] font-medium font-['Circular Std'] leading-[34px]">
            Congratulations!
          </div>
          <div className="w-[243px] text-center text-[#121212]/70 text-xl font-medium font-['Circular Std'] leading-snug">
            Your vehicle is now live.
          </div>
        </div>
      </div>
      <div
        className="w-[420px] h-[60px] p-3 bg-[#f46702] rounded-xl shadow border border-black/60 justify-center items-center gap-3 inline-flex cursor-pointer"
        onClick={() => closeModelFunction()}
      >
        <Link to={"/dashboard/"} className="text-center text-white text-xl font-medium font-['Circular Std'] leading-[26.96px]">
          View dashboard
        </Link>
      </div>
    </div>
  );
}
