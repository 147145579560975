export function CarIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M5.75 20.1665L8.75 21.6665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.25 20.9165L31.25 21.6665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 28.4165L14.3685 27.4952C14.9165 26.1253 15.1905 25.4402 15.7619 25.0534C16.3333 24.6665 17.0711 24.6665 18.5467 24.6665H21.4533C22.9289 24.6665 23.6668 24.6665 24.2381 25.0534C24.8095 25.4402 25.0835 26.1253 25.6315 27.4952L26 28.4165"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 27.6665V31.9895C5 32.5576 5.36113 33.077 5.93282 33.3311C6.30377 33.496 6.65807 33.6665 7.08587 33.6665H9.66413C10.0919 33.6665 10.4462 33.496 10.8172 33.3311C11.3889 33.077 11.75 32.5576 11.75 31.9895V29.1665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.25 29.1665V31.9895C28.25 32.5576 28.6112 33.077 29.1829 33.3311C29.5538 33.496 29.9081 33.6665 30.3359 33.6665H32.9141C33.3419 33.6665 33.6962 33.496 34.0671 33.3311C34.6388 33.077 35 32.5576 35 31.9895V27.6665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 14.9165L33.5 14.1665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.9165L6.5 14.1665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 15.6665L10.3825 10.7691C11.0425 8.78908 11.3725 7.79905 12.1581 7.23278C12.9438 6.6665 13.9874 6.6665 16.0746 6.6665H23.9255C26.0126 6.6665 27.0561 6.6665 27.8418 7.23278C28.6275 7.79905 28.9575 8.78908 29.6175 10.7691L31.25 15.6665"
        stroke="#121212"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 15.6665H31.25C32.6858 17.1868 35 19.3039 35 21.6659V26.8718C35 27.7276 34.4308 28.4477 33.6752 28.5478L29 29.1665H11L6.32474 28.5478C5.56933 28.4477 5 27.7276 5 26.8718V21.6659C5 19.3039 7.31418 17.1868 8.75 15.6665Z"
        stroke="#121212"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BikeIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M32.5007 31.6668C34.8018 31.6668 36.6673 29.8013 36.6673 27.5002C36.6673 25.199 34.8018 23.3335 32.5007 23.3335C30.1995 23.3335 28.334 25.199 28.334 27.5002C28.334 29.8013 30.1995 31.6668 32.5007 31.6668Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M7.50065 31.6668C9.80184 31.6668 11.6673 29.8013 11.6673 27.5002C11.6673 25.199 9.80184 23.3335 7.50065 23.3335C5.19946 23.3335 3.33398 25.199 3.33398 27.5002C3.33398 29.8013 5.19946 31.6668 7.50065 31.6668Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M33.7253 13.1161C35.8612 15.7151 36.6032 18.1863 36.6663 19.493C35.5497 18.9823 34.2958 18.6958 32.9708 18.6958C28.8955 18.6958 25.2372 21.4057 24.3635 25.0155C24.1547 25.8785 24.0503 26.31 23.8235 26.4883C23.5968 26.6668 23.231 26.6668 22.4997 26.6668H17.7017C16.9647 26.6668 16.5962 26.6668 16.3686 26.486C16.1409 26.305 16.0412 25.875 15.8417 25.0148C15.0155 21.4515 11.6618 18.3542 8.17061 18.6165C7.81881 18.643 7.64291 18.6562 7.52222 18.6293C7.40156 18.6023 7.28006 18.5338 7.03707 18.3965C6.33561 18.0003 5.61834 17.6242 4.93054 17.2047C3.97231 16.6201 3.37257 15.5926 3.33487 14.4708C3.31342 13.8324 3.68061 13.1979 4.42234 13.3725L15.1235 15.8907C15.9273 16.0799 16.3293 16.1745 16.6747 16.1101C17.02 16.0457 17.4433 15.7562 18.2902 15.1771C20.4373 13.7088 23.8402 12.3219 27.2323 13.5472C28.1608 13.8826 28.6252 14.0503 28.8897 14.0587C29.1542 14.0671 29.5418 13.9543 30.3172 13.7286C31.5725 13.3632 32.7542 13.1736 33.7253 13.1161ZM33.7253 13.1161C32.3867 11.487 30.5002 9.8077 27.89 8.3335"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function VanIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M20 10L21.5893 16.4329C21.865 17.5492 22.8667 18.3333 24.0163 18.3333H33.3333"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 10H21.7822C24.3633 10 25.6538 10 26.748 10.5766C27.8423 11.1532 28.5718 12.2178 30.031 14.347C31.0542 15.8399 32.1282 16.9237 33.6383 17.8877C35.1588 18.8583 35.8818 19.3327 36.283 20.0943C36.6673 20.8237 36.6673 21.6875 36.6673 23.415C36.6673 25.6927 36.6673 26.8315 35.9798 27.5552C35.9505 27.5862 35.9202 27.6165 35.8892 27.6458C35.1655 28.3333 34.0267 28.3333 31.749 28.3333M8.33398 28.3333C7.79942 28.3333 7.309 28.3333 7.04963 28.2777C6.79027 28.222 6.54652 28.1123 6.05903 27.893L3.33398 26.6667C3.33398 21.3427 4.13238 18.2705 5.1766 15.7499C5.86132 14.0971 6.20368 13.2707 6.06208 12.5344C5.92048 11.7981 4.16732 10 4.16732 10M15.0007 28.3333H25.0007"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3333 31.6667C30.1743 31.6667 31.6667 30.1743 31.6667 28.3333C31.6667 26.4924 30.1743 25 28.3333 25C26.4924 25 25 26.4924 25 28.3333C25 30.1743 26.4924 31.6667 28.3333 31.6667Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M11.6673 31.6667C13.5083 31.6667 15.0007 30.1743 15.0007 28.3333C15.0007 26.4924 13.5083 25 11.6673 25C9.82637 25 8.33398 26.4924 8.33398 28.3333C8.33398 30.1743 9.82637 31.6667 11.6673 31.6667Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function LorryIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M17.166 15V18.3333"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M28.8333 33.3332C30.6743 33.3332 32.1667 31.8408 32.1667 29.9998C32.1667 28.1589 30.6743 26.6665 28.8333 26.6665C26.9924 26.6665 25.5 28.1589 25.5 29.9998C25.5 31.8408 26.9924 33.3332 28.8333 33.3332Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M12.1673 33.3332C14.0083 33.3332 15.5007 31.8408 15.5007 29.9998C15.5007 28.1589 14.0083 26.6665 12.1673 26.6665C10.3264 26.6665 8.83398 28.1589 8.83398 29.9998C8.83398 31.8408 10.3264 33.3332 12.1673 33.3332Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M8.83398 29.9538C7.00612 29.8628 5.86582 29.5908 5.05437 28.7795C3.83398 27.559 3.83398 25.5948 3.83398 21.6665V14.9998C3.83398 11.0715 3.83398 9.10729 5.05437 7.88689C6.27477 6.6665 8.23895 6.6665 12.1673 6.6665H17.6673C19.5287 6.6665 20.4593 6.6665 21.2125 6.91122C22.7347 7.4058 23.928 8.59924 24.4227 10.1214C24.6673 10.8746 24.6673 11.8052 24.6673 13.6665C24.6673 14.9074 24.6673 15.5278 24.8305 16.0299C25.1602 17.0447 25.9558 17.8403 26.9707 18.17C27.4727 18.3332 28.0931 18.3332 29.334 18.3332H37.1673V21.6665C37.1673 25.5948 37.1673 27.559 35.947 28.7795C35.1355 29.5908 33.9952 29.8628 32.1673 29.9538M15.5007 29.9998H25.5007"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.666 10H27.7013C30.127 10 31.3397 10 32.3267 10.5895C33.3137 11.179 33.8887 12.2469 35.0387 14.3825L37.166 18.3333"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15V18.3333"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function DropDownArrowIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M30 15.0001C30 15.0001 22.6352 25 20 25C17.3647 25 10 15 10 15"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FilterSearchIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        d="M1 21C1 11.5719 1 6.85787 3.92893 3.92893C6.85787 1 11.5719 1 21 1C30.428 1 35.1421 1 38.0712 3.92893C41 6.85787 41 11.5719 41 21C41 30.428 41 35.1421 38.0712 38.0712C35.1421 41 30.428 41 21 41C11.5719 41 6.85787 41 3.92893 38.0712C1 35.1421 1 30.428 1 21Z"
        stroke="#F46702"
        strokeWidth="2"
      />
      <path
        d="M14.6836 21H27.3154"
        stroke="#F46702"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7891 28.3682H25.2101"
        stroke="#F46702"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5781 13.6318H29.4202"
        stroke="#F46702"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function TickGoodIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.99805 12.4692C2.99805 7.9909 2.99805 5.75173 4.38929 4.36048C5.78054 2.96924 8.01971 2.96924 12.498 2.96924C16.9763 2.96924 19.2155 2.96924 20.6068 4.36048C21.998 5.75173 21.998 7.9909 21.998 12.4692C21.998 16.9475 21.998 19.1867 20.6068 20.578C19.2155 21.9692 16.9763 21.9692 12.498 21.9692C8.01971 21.9692 5.78054 21.9692 4.38929 20.578C2.99805 19.1867 2.99805 16.9475 2.99805 12.4692Z"
        stroke="#121212"
        strokeWidth="1.5"
      />
      <path
        d="M8.5 12.9697L11 15.4697L16.5 9.46973"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function TickBadIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M15.5 9.46973L9.5 15.4693M15.5 15.4697L9.5 9.47012"
        stroke="#F00000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12.4697C3 7.99139 3 5.75222 4.39124 4.36097C5.78249 2.96973 8.02166 2.96973 12.5 2.96973C16.9783 2.96973 19.2175 2.96973 20.6088 4.36097C22 5.75222 22 7.99139 22 12.4697C22 16.948 22 19.1872 20.6088 20.5785C19.2175 21.9697 16.9783 21.9697 12.5 21.9697C8.02166 21.9697 5.78249 21.9697 4.39124 20.5785C3 19.1872 3 16.948 3 12.4697Z"
        stroke="#F00000"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function BackArrowIconSvg() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-left-02">
        <path
          id="Vector"
          d="M6.66602 20.5H33.3327"
          stroke="#121212"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.9993 28.8332C14.9993 28.8332 6.66603 22.6958 6.66602 20.4998C6.666 18.3038 14.9993 12.1665 14.9993 12.1665"
          stroke="#121212"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function MoneyPrizeIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M15.9993 26.7498C14.2227 27.5797 11.8888 28.0832 9.33268 28.0832C7.91146 28.0832 6.55894 27.9276 5.33268 27.6466C3.22372 27.1636 2.66602 25.9226 2.66602 23.9312V9.56846C2.66602 8.25536 4.05272 7.35348 5.33268 7.64664C6.55894 7.9275 7.91146 8.08317 9.33268 8.08317C11.8888 8.08317 14.2227 7.57965 15.9993 6.74984C17.776 5.92002 20.1099 5.4165 22.666 5.4165C24.0872 5.4165 25.4397 5.57217 26.666 5.85304C28.7749 6.33608 29.3327 7.57698 29.3327 9.56846V23.9312C29.3327 25.2444 27.946 26.1462 26.666 25.853C25.4397 25.5721 24.0872 25.4165 22.666 25.4165C20.1099 25.4165 17.776 25.92 15.9993 26.7498Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M19.3327 16.7498C19.3327 18.5908 17.8403 20.0832 15.9993 20.0832C14.1584 20.0832 12.666 18.5908 12.666 16.7498C12.666 14.9089 14.1584 13.4165 15.9993 13.4165C17.8403 13.4165 19.3327 14.9089 19.3327 16.7498Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M7.33398 18.0835V18.0955"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.666 15.4062V15.4182"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MapLocationIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M19.3327 12.7498C19.3327 14.5908 17.8403 16.0832 15.9993 16.0832C14.1584 16.0832 12.666 14.5908 12.666 12.7498C12.666 10.9089 14.1584 9.4165 15.9993 9.4165C17.8403 9.4165 19.3327 10.9089 19.3327 12.7498Z"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="1.5"
      />
      <path
        d="M24.2969 23.4165C26.1562 26.0678 27.0457 27.4798 26.516 28.6164C26.4628 28.7304 26.4005 28.8404 26.3298 28.9454C25.5638 30.0832 23.584 30.0832 19.6244 30.0832H12.377C8.41733 30.0832 6.43751 30.0832 5.67146 28.9454C5.60071 28.8404 5.5385 28.7304 5.48538 28.6164C4.95557 27.4798 5.84499 26.0678 7.70435 23.4165"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6759 24.0746C17.2261 24.5077 16.6251 24.7498 15.9996 24.7498C15.374 24.7498 14.7729 24.5077 14.3232 24.0746C10.2051 20.0842 4.68627 15.6265 7.37763 9.15481C8.83282 5.6556 12.3259 3.4165 15.9996 3.4165C19.6732 3.4165 23.1663 5.65561 24.6215 9.15481C27.3095 15.6184 21.8041 20.098 17.6759 24.0746Z"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function RightCoveArrowIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M12.0001 8.75C12.0001 8.75 20 14.6419 20 16.75C20 18.8583 12 24.75 12 24.75"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MessageIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M20.5 10.3167C20.5 15.5999 16.0222 19.8834 10.5 19.8834C9.8507 19.8843 9.2032 19.8242 8.5654 19.7045C8.10633 19.6182 7.87678 19.5751 7.71653 19.5996C7.55627 19.6241 7.32918 19.7448 6.87499 19.9864C5.59014 20.6697 4.09195 20.911 2.65111 20.643C3.19874 19.9694 3.57275 19.1612 3.73778 18.2948C3.83778 17.7648 3.59 17.25 3.21889 16.8731C1.53333 15.1615 0.5 12.8551 0.5 10.3167C0.5 5.03357 4.97778 0.75 10.5 0.75C16.0222 0.75 20.5 5.03357 20.5 10.3167Z"
        fill="black"
        fillOpacity="0.8"
      />
      <path
        d="M10.4955 10.75H10.5045M14.491 10.75H14.5M6.5 10.75H6.50897"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PhoneIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M6.15826 3.46223L5.7556 2.55625C5.49232 1.96388 5.36068 1.66768 5.16381 1.44101C4.91708 1.15694 4.59547 0.94794 4.23568 0.83785C3.94859 0.75 3.62446 0.75 2.97621 0.75C2.02791 0.75 1.55376 0.75 1.15573 0.93229C0.686867 1.14702 0.263437 1.61328 0.0947272 2.1006C-0.0484928 2.51429 -0.00746276 2.93943 0.0745772 3.7897C0.947907 12.8402 5.90982 17.8021 14.9603 18.6754C15.8106 18.7575 16.2358 18.7985 16.6494 18.6553C17.1368 18.4866 17.603 18.0631 17.8178 17.5943C18 17.1962 18 16.7221 18 15.7738C18 15.1255 18 14.8014 17.9122 14.5143C17.8021 14.1545 17.5931 13.8329 17.309 13.5862C17.0824 13.3893 16.7862 13.2577 16.1938 12.9944L15.2878 12.5917C14.6463 12.3066 14.3255 12.1641 13.9996 12.1331C13.6876 12.1034 13.3731 12.1472 13.0811 12.2609C12.776 12.3797 12.5064 12.6044 11.967 13.0538C11.4302 13.5012 11.1618 13.7249 10.8338 13.8447C10.543 13.9509 10.1586 13.9903 9.85236 13.9451C9.50686 13.8942 9.24236 13.7529 8.71326 13.4701C7.06726 12.5905 6.15953 11.6828 5.27987 10.0367C4.99714 9.5077 4.85578 9.2431 4.80487 8.8977C4.75974 8.5914 4.79908 8.207 4.9053 7.9163C5.02512 7.58828 5.24881 7.31986 5.69619 6.783C6.14562 6.24368 6.37034 5.97402 6.48915 5.66891C6.60285 5.37694 6.64662 5.0624 6.61695 4.75048C6.58594 4.42452 6.44338 4.10376 6.15826 3.46223Z"
        fill="#121212"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export function TickCheckBoxIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M4.16602 20.8748C4.16602 13.4109 4.16602 9.67899 6.48475 7.36024C8.8035 5.0415 12.5354 5.0415 19.9993 5.0415C27.4632 5.0415 31.1952 5.0415 33.514 7.36024C35.8327 9.67899 35.8327 13.4109 35.8327 20.8748C35.8327 28.3387 35.8327 32.0707 33.514 34.3895C31.1952 36.7082 27.4632 36.7082 19.9993 36.7082C12.5354 36.7082 8.8035 36.7082 6.48475 34.3895C4.16602 32.0707 4.16602 28.3387 4.16602 20.8748Z"
        stroke="#121212"
        strokeWidth="3"
      />
      <path
        d="M13.334 21.7083L17.5007 25.875L26.6673 15.875"
        stroke="#121212"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RevenueIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M31.5725 28.0588C30.4786 21.4775 27.0733 16.5767 24.113 13.6982C23.2515 12.8605 22.8208 12.4417 21.8693 12.0541C20.9178 11.6665 20.1 11.6665 18.4643 11.6665H14.8716C13.236 11.6665 12.4181 11.6665 11.4667 12.0541C10.5152 12.4417 10.0845 12.8605 9.22303 13.6982C6.26266 16.5767 2.85732 21.4775 1.76342 28.0588C0.949515 32.9555 5.46675 36.6665 10.5152 36.6665H22.8208C27.8691 36.6665 32.3865 32.9555 31.5725 28.0588Z"
        stroke="#121212"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75973 7.40495C8.41588 6.90446 7.9175 6.22515 8.94701 6.07025C10.0052 5.91101 11.104 6.6354 12.1796 6.62051C13.1526 6.60705 13.6484 6.17548 14.1802 5.5593C14.7402 4.91045 15.6074 3.3335 16.6654 3.3335C17.7234 3.3335 18.5905 4.91045 19.1505 5.5593C19.6824 6.17548 20.178 6.60705 21.151 6.62051C22.2267 6.6354 23.3255 5.91101 24.3837 6.07025C25.4132 6.22515 24.9149 6.90446 24.571 7.40495L23.0162 9.6679C22.3512 10.6359 22.0187 11.1199 21.3227 11.3934C20.6269 11.6668 19.7275 11.6668 17.929 11.6668H15.4017C13.6032 11.6668 12.7039 11.6668 12.008 11.3934C11.3121 11.1199 10.9795 10.6359 10.3144 9.6679L8.75973 7.40495Z"
        stroke="#121212"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.3759 21.5311C19.0156 20.201 17.1815 19.0006 14.98 19.8986C12.7785 20.7965 12.4288 23.6856 15.7589 23.9926C17.2639 24.1313 18.2452 23.8316 19.1435 24.6795C20.042 25.5271 20.2089 27.8848 17.9122 28.5201C15.6155 29.1555 13.3414 28.1628 13.0938 26.753M16.4009 18.3213V19.5886M16.4009 28.7156V29.988"
        stroke="#121212"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function InspectionIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M14.9712 3.3335C10.2935 3.44008 7.56133 3.88535 5.69456 5.74565C4.06075 7.3738 3.5146 9.66256 3.33203 13.3335M25.0262 3.3335C29.7039 3.44008 32.436 3.88535 34.3029 5.74565C35.9367 7.3738 36.4829 9.66256 36.6654 13.3335M25.0262 36.6669C29.7039 36.5604 32.436 36.115 34.3029 34.2547C35.9367 32.6265 36.4829 30.3379 36.6654 26.6669M14.9712 36.6669C10.2935 36.5604 7.56133 36.115 5.69456 34.2547C4.06075 32.6265 3.5146 30.3379 3.33203 26.6669"
        stroke="#121212"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0013 24.9999L28.3346 28.3332M26.668 19.1665C26.668 15.0243 23.3101 11.6665 19.168 11.6665C15.0258 11.6665 11.668 15.0243 11.668 19.1665C11.668 23.3087 15.0258 26.6665 19.168 26.6665C23.3101 26.6665 26.668 23.3087 26.668 19.1665Z"
        stroke="#121212"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HostedVehiclesIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        opacity="0.2"
        cx="20"
        cy="20"
        r="20"
        fill="black"
        fillOpacity="0.4"
      />
      <circle
        opacity="0.4"
        cx="20"
        cy="20"
        r="15"
        fill="black"
        fillOpacity="0.4"
      />
      <circle cx="20" cy="20" r="7.5" fill="black" />
    </svg>
  );
}


export function GoldProfileStarIconSvg() {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40049 0.982633L4.9172 2.0246C4.98767 2.16965 5.17555 2.30877 5.33411 2.33541L6.27064 2.4923C6.86956 2.59295 7.0105 3.03105 6.57891 3.46323L5.85082 4.19733C5.7275 4.32165 5.66 4.56142 5.69814 4.73313L5.90661 5.64188C6.07101 6.36119 5.69227 6.63946 5.06107 6.26351L4.18324 5.73957C4.02471 5.64485 3.76342 5.64485 3.60193 5.73957L2.72411 6.26351C2.09583 6.63946 1.71417 6.35823 1.87858 5.64188L2.08703 4.73313C2.12519 4.56142 2.05767 4.32165 1.93436 4.19733L1.20627 3.46323C0.777629 3.03105 0.915616 2.59295 1.51453 2.4923L2.45108 2.33541C2.60668 2.30877 2.79457 2.16965 2.86503 2.0246L3.38174 0.982633C3.66359 0.417247 4.12158 0.417247 4.40049 0.982633Z"
        fill="#F46702"
      />
    </svg>
  );
}

export function CreateAccountIconSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C7.67837 14.2307 10.1368 13.7719 12.5 14.1052C13.3575 14.2261 14.1926 14.4514 15 14.7809"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
        stroke="#121212"
        stroke-width="1.5"
      />
      <path
        d="M18.5 22V15M15 18.5H22"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function LoginIconSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C7.67837 14.2307 10.1368 13.7719 12.5 14.1052C13.3575 14.2261 14.1926 14.4514 15 14.7809"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 6.5C15.5 8.98528 13.4853 11 11 11C8.51472 11 6.5 8.98528 6.5 6.5C6.5 4.01472 8.51472 2 11 2C13.4853 2 15.5 4.01472 15.5 6.5Z"
        stroke="#121212"
        stroke-width="1.5"
      />
      <path
        d="M12.9938 18.15C12.6624 18.15 12.3938 18.4186 12.3938 18.75C12.3938 19.0814 12.6624 19.35 12.9938 19.35V18.15ZM23.4305 19.1743C23.6648 18.9399 23.6648 18.5601 23.4305 18.3257L19.6122 14.5074C19.3778 14.273 18.9979 14.273 18.7636 14.5074C18.5293 14.7417 18.5293 15.1216 18.7636 15.3559L22.1577 18.75L18.7636 22.1441C18.5293 22.3784 18.5293 22.7583 18.7636 22.9926C18.9979 23.227 19.3778 23.227 19.6122 22.9926L23.4305 19.1743ZM12.9938 19.35H23.0063V18.15H12.9938V19.35Z"
        fill="#121212"
      />
    </svg>
  );
}

export function LogoutIonSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 17.625C14.9264 19.4769 13.3831 21.0494 11.3156 20.9988C10.8346 20.987 10.2401 20.8194 9.05112 20.484C6.18961 19.6768 3.70555 18.3203 3.10956 15.2815C3 14.723 3 14.0944 3 12.8373V11.1627C3 9.90561 3 9.27705 3.10956 8.71846C3.70555 5.67965 6.18961 4.32316 9.05112 3.51603C10.2401 3.18064 10.8346 3.01295 11.3156 3.00119C13.3831 2.95061 14.9264 4.52307 15 6.37501"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M21 12H10M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function DashboardIconSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
        stroke="#121212"
        stroke-width="1.5"
      />
      <path
        d="M2.5 12H21.5"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 7H17"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 8.25C8.94036 8.25 9.5 7.69036 9.5 7C9.5 6.30964 8.94036 5.75 8.25 5.75C7.55964 5.75 7 6.30964 7 7C7 7.69036 7.55964 8.25 8.25 8.25Z"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 18.25C8.94036 18.25 9.5 17.6904 9.5 17C9.5 16.3096 8.94036 15.75 8.25 15.75C7.55964 15.75 7 16.3096 7 17C7 17.6904 7.55964 18.25 8.25 18.25Z"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 17H17"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
} 