import React, { useState, useEffect } from 'react';

const LocationInput = ({ onSelectLocation }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputValue) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const fetchSuggestions = async () => {
    setLoading(true);
    const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${inputValue}&key=AIzaSyCRgHodPQfTmkrhtaMSWvwucEhl7jkhr0k`);
    const data = await response.json();
    setSuggestions(data.predictions);
    setLoading(false);
  };

  const handleSelect = (place) => {
    onSelectLocation(place);
    setInputValue('');
    setSuggestions([]);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter location"
        className="border rounded p-2"
      />
      {loading && <div>Loading...</div>}
      {suggestions.length > 0 && (
        <ul className="absolute border bg-white w-full max-h-60 overflow-auto z-10">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion)}
              className="p-2 hover:bg-gray-200 cursor-pointer"
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationInput;