import React, { useContext } from "react";
import { ModalContext } from "../../Context/modalContext";

function ModelPopupComponent({ trigger, children }) {
  const { isModalOpen, closeModelFunction, openModelFunction } =
    useContext(ModalContext);
  return (
    <>
      <div onClick={() => openModelFunction()} className="w-full">
        {trigger}
      </div>
      {isModalOpen && (
        <div className="ModelPopupComponent flex justify-center fixed b-0 md:pt-[10%] overflow-x-scroll border  h-full md:h-[auto] w-full bottom-0 top-0 left-0 right-0 bg-[#0000005f]">
          {children}
        </div>
      )}
    </>
  );
}

export default ModelPopupComponent;
